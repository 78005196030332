import React, {Component} from "react";
import './WarehouseActions.css';

//Components
import {Route} from "react-router-dom";
import WarehouseDashboard from "./WarehouseDashboard/WarehouseDashboard";
import Inventory from "./Inventory/Inventory";
import Reporting from "./Reporting/Reporting";
import MasterData from "../../CommonComponents/Actions/MasterData/MasterData";
import Settings from "../../CommonComponents/Actions/Settings/Settings";
import Administration from "../../CommonComponents/Actions/Administration/Administration";
import OrderManagement from "../../CommonComponents/Actions/OrderManagement/OrderManagement";
import Communication from "../../CommonComponents/Actions/Communication/Communication";

class WarehouseActions extends Component {
    render() {
        return (
            <div className='warehouse-actions'>
                <Route exact path='/warehouse-manager'>
                    <WarehouseDashboard
                        user={this.props.user}
                        logout={this.props.logout}
                        setLoading={this.props.setLoading}
                        downloadFile={this.props.downloadFile}
                    />
                </Route>

                <Route path='/warehouse-manager/order-management'>
                    <OrderManagement
                        //Common
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        analyzes={this.props.analyzes}
                        countries={this.props.countries}
                        round={this.props.round}
                        generateDocument={this.props.generateDocument}
                        formatDate={this.props.formatDate}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        //User
                        user={this.props.user}
                        updateDefaultCustomerOrderFilter={this.props.updateDefaultCustomerOrderFilter}
                        updateDefaultSupplierOrderFilter={this.props.updateDefaultSupplierOrderFilter}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        getArticleInformation={this.props.getArticleInformation}
                        articleInformation={this.props.articleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}
                        addArticleToList={this.props.addArticleToList}

                        //Locations
                        locations={this.props.locations}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addSupplierToList={this.props.addSupplierToList}

                        //Attributes
                        attributes={this.props.attributes}

                        //Reservations
                        reservations={this.props.reservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}
                        bookCustomerOrderInventoryTransactions={this.props.bookCustomerOrderInventoryTransactions}
                        bookSupplierOrderInventoryTransactions={this.props.bookSupplierOrderInventoryTransactions}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        addNewCustomerOrder={this.props.addNewCustomerOrder}
                        updateCustomerOrder={this.props.updateCustomerOrder}
                        deleteCustomerOrder={this.props.deleteCustomerOrder}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Account Plan
                        accountPlan={this.props.accountPlan}
                        loadAccountPlan={this.props.loadAccountPlan}
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Email
                        sendEmail={this.props.sendEmail}
                        addEmailAttachment={this.props.addEmailAttachment}
                        emailAttachments={this.props.emailAttachments}
                        removeEmailAttachment={this.props.removeEmailAttachment}
                        emailReferencedOrder={this.props.emailReferencedOrder}
                        setEmailReferencedOrder={this.props.setEmailReferencedOrder}

                        //Filters
                        filters={this.props.filters}
                        addFilterToList={this.props.addFilterToList}
                        removeFilterFromList={this.props.removeFilterFromList}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>

                <Route path='/warehouse-manager/inventory'>
                    <Inventory
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        round={this.props.round}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}
                        user={this.props.user}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        addArticleToList={this.props.addArticleToList}
                        getArticleInformation={this.props.getArticleInformation}
                        articleInformation={this.props.articleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}

                        //Locations
                        locations={this.props.locations}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addSupplierToList={this.props.addSupplierToList}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}

                        //PartsLists
                        partsLists={this.props.partsLists}
                        addPartsListToList={this.props.addPartsListToList}
                        removePartsListFromList={this.props.removePartsListFromList}

                        //Reservations
                        reservations={this.props.reservations}
                        addReservationToList={this.props.addReservationToList}
                        removeReservationFromList={this.props.removeReservationFromList}

                        //Account Plan
                        saveAccountPlan={this.props.saveAccountPlan}
                        updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}

                        //Accounts
                        accounts={this.props.accounts}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        // Batches
                        batches={this.props.batches}
                        addBatchToList={this.props.addBatchToList}
                        updateBatchInList={this.props.updateBatchInList}
                        removeBatchFromList={this.props.removeBatchFromList}
                        batchInformation={this.props.batchInformation}
                        displayBatchInformation={this.props.displayBatchInformation}
                    />
                </Route>

                <Route path='/warehouse-manager/communication'>
                    <Communication
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        round={this.props.round}
                        setLoading={this.props.setLoading}
                        mailTo={this.props.mailTo}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        addArticleToList={this.props.addArticleToList}
                        getArticleInformation={this.props.getArticleInformation}
                        articleInformation={this.props.articleInformation}
                        displayArticleInformation={this.props.displayArticleInformation}

                        //Files
                        displayFile={this.props.displayFile}

                        //Locations
                        locations={this.props.locations}

                        //Customers
                        customers={this.props.customers}
                        addCustomerToList={this.props.addCustomerToList}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addSupplierToList={this.props.addSupplierToList}

                        //Transactions
                        setSelectedTransaction={this.props.setSelectedTransaction}
                        undoSelectedTransaction={this.props.undoSelectedTransaction}
                        clearTransactions={this.props.clearTransactions}
                        removeTransactionFromHistory={this.props.removeTransactionFromHistory}
                        transactions={this.props.transactions}
                        addTransactionToHistory={this.props.addTransactionToHistory}

                        //CustomerOrders
                        customerOrders={this.props.customerOrders}
                        addCustomerOrderToList={this.props.addCustomerOrderToList}
                        removeCustomerOrderFromList={this.props.removeCustomerOrderFromList}

                        //SupplierOrders
                        supplierOrders={this.props.supplierOrders}
                        addSupplierOrderToList={this.props.addSupplierOrderToList}
                        removeSupplierOrderFromList={this.props.removeSupplierOrderFromList}
                    />
                </Route>

                <Route path='/warehouse-manager/master-data'>
                    <MasterData
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        setLoading={this.props.setLoading}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}

                        //Articles
                        articles={this.props.articles}
                        addNewArticle={this.props.addNewArticle}
                        addMultipleArticles={this.props.addMultipleArticles}
                        updateArticle={this.props.updateArticle}
                        deleteArticle={this.props.deleteArticle}

                        //Files
                        saveFile={this.props.saveFile}
                        displayFile={this.props.displayFile}
                        loadFile={this.props.loadFile}

                        //Locations
                        locations={this.props.locations}
                        addNewLocation={this.props.addNewLocation}
                        addMultipleLocations={this.props.addMultipleLocations}
                        updateLocation={this.props.updateLocation}
                        deleteLocation={this.props.deleteLocation}

                        //Customers
                        customers={this.props.customers}
                        addNewCustomer={this.props.addNewCustomer}
                        addMultipleCustomers={this.props.addMultipleCustomers}
                        updateCustomer={this.props.updateCustomer}
                        deleteCustomer={this.props.deleteCustomer}

                        //Suppliers
                        suppliers={this.props.suppliers}
                        addNewSupplier={this.props.addNewSupplier}
                        addMultipleSuppliers={this.props.addMultipleSuppliers}
                        updateSupplier={this.props.updateSupplier}
                        deleteSupplier={this.props.deleteSupplier}

                        //Attributes
                        attributes={this.props.attributes}
                        addNewAttribute={this.props.addNewAttribute}
                        addMultipleAttributes={this.props.addMultipleAttributes}
                        updateAttribute={this.props.updateAttribute}
                        deleteAttribute={this.props.deleteAttribute}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                        addNewArticleGroup={this.props.addNewArticleGroup}
                        updateArticleGroup={this.props.updateArticleGroup}
                        deleteArticleGroup={this.props.deleteArticleGroup}

                        //Accounts
                        accounts={this.props.accounts}

                        //MessageTemplates
                        messageTemplates={this.props.messageTemplates}
                        loadMessageTemplates={this.props.loadMessageTemplates}
                        addNewMessageTemplate={this.props.addNewMessageTemplate}
                        updateMessageTemplate={this.props.updateMessageTemplate}
                        addMessageTemplateToList={this.props.addMessageTemplateToList}
                        deleteMessageTemplate={this.props.deleteMessageTemplate}
                    />
                </Route>

                <Route path='/warehouse-manager/reporting'>
                    <Reporting
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        attributes={this.props.attributes}
                        customers={this.props.customers}
                        suppliers={this.props.suppliers}
                        setLoading={this.props.setLoading}
                        downloadFile={this.props.downloadFile}
                    />
                </Route>

                <Route path='/warehouse-manager/administration'>
                    <Administration
                        //Common
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        setLoading={this.props.setLoading}
                        downloadFile={this.props.downloadFile}

                        //Company
                        company={this.props.company}
                        updateCompany={this.props.updateCompany}

                        //Users
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUser={this.props.updateUser}
                        deleteUser={this.props.deleteUser}
                        changePassword={this.props.changePassword}
                        changeAssignedLocation={this.props.changeAssignedLocation}

                        //Locations
                        locations={this.props.locations}

                        //Accounts
                        accounts={this.props.accounts}

                        //Article Groups
                        articleGroups={this.props.articleGroups}
                    />
                </Route>

                <Route path='/warehouse-manager/settings'>
                    <Settings
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        changeOwnPassword={this.props.changeOwnPassword}
                        updateUserInformation={this.props.updateUserInformation}
                        user={this.props.user}
                        setLoading={this.props.setLoading}
                    />
                </Route>
            </div>
        )
    }
}

export default WarehouseActions;