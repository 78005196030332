import React, { useEffect, useState} from "react";
import './GenerateDocumentDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../../../../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import DocumentPosition from "../../../../../../../../../reusable/DocumentPosition/DocumentPosition";
import api from "../../../../../../../../../api";
import jsPDF from "jspdf";
import HelperFunctions from "../../../../../../../../../reusable/HelperFunctions";
import YesCancelDialog from "../../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import helperFunctions from "../../../../../../../../../reusable/HelperFunctions";
import { Tooltip } from 'react-tooltip'
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import Paper from "@mui/material/Paper";
import _ from "lodash";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

const GenerateDocumentDialog = (props) => {

    const [order, setOrder] = useState(props.order);
    const [lastGeneratedOrder, setLastGeneratedOrder] = useState(null);
    const [documentKind, setDocumentKind] = useState(null);
    const [generateDocumentWarningDialog, setGenerateDocumentWarningDialog] = useState(false);
    const [allCheck, setAllCheck] = useState(false);
    const [positionIndex, setPositionIndex] = useState(0);
    const [overtakeInformationDialog, setOvertakeInformationDialog] = useState(null);
    const [positionDisplay,setPositionDisplay] = useState(true);
    const [eInvoice,setEInvoice] = useState(true);

    useEffect(() => {
        let generateOrder = _.cloneDeep(props.order);
        generateOrder.documentPositions = _.cloneDeep(generateOrder.documentPositions);
        generateOrder.documentPositions.sort((a,b) => a.position - b.position);
        generateOrder.invoiceNr = null;
        setOrder(generateOrder);
    }, []);

    const documentKindOnChangeHandler = (e) => {
        const updatedOrder = { ...order };
        switch (e.target.value) {
            case "INVOICE":
                updatedOrder.invoiceNr = null;
                updatedOrder.documentDate = null;
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultInvoiceDescription) {
                    updatedOrder.description = props.company.defaultInvoiceDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultInvoiceEndDescription) {
                    updatedOrder.endDescription = props.company.defaultInvoiceEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount - props.order.documentPositions[j].inventoryCountInvoice;
                            if (updatedOrder.documentPositions[i].inventoryCount > 0) {
                                updatedOrder.documentPositions[i].active = true;
                            } else {
                                updatedOrder.documentPositions[i].inventoryCount = 0;
                                updatedOrder.documentPositions[i].active = false;
                            }
                            break;
                        }
                    }
                }
                break;
            case "DELIVERY_BILL":
                updatedOrder.deliveryBillDate = null;
                updatedOrder.deliveryBillNr = null;
                updatedOrder.documentDate = null;
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultDeliveryBillDescription) {
                    updatedOrder.description = props.company.defaultDeliveryBillDescription;
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultDeliveryBillEndDescription) {
                    updatedOrder.endDescription = props.company.defaultDeliveryBillEndDescription;
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount - props.order.documentPositions[j].inventoryCountDeliveryBill;
                            if (updatedOrder.documentPositions[i].inventoryCount > 0) {
                                updatedOrder.documentPositions[i].active = true;
                            } else {
                                updatedOrder.documentPositions[i].inventoryCount = 0;
                                updatedOrder.documentPositions[i].active = false;
                            }
                            break;
                        }
                    }
                }
                break;
            case "OFFER":
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultOfferDescription) {
                    updatedOrder.description = props.company.defaultOfferDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultOfferEndDescription) {
                    updatedOrder.endDescription = props.company.defaultOfferEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].active = true;
                            break;
                        }
                    }
                }
                break;
            case "ORDER_CONFIRMATION":
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultOrderConfirmationDescription) {
                    updatedOrder.description = props.company.defaultOrderConfirmationDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultOrderConfirmationEndDescription) {
                    updatedOrder.endDescription = props.company.defaultOrderConfirmationEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].active = true;
                            break;
                        }
                    }
                }
                break;
            case "ORDER":
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultOrderDescription) {
                    updatedOrder.description = props.company.defaultOrderDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultOrderEndDescription) {
                    updatedOrder.endDescription = props.company.defaultOrderEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].active = true;
                            break;
                        }
                    }
                }
                break;
            case "REQUEST":
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultRequestDescription) {
                    updatedOrder.description = props.company.defaultRequestDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultRequestEndDescription) {
                    updatedOrder.endDescription = props.company.defaultRequestEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].active = true;
                            break;
                        }
                    }
                }
                break;
            case "CANCELLATION_INVOICE":
                if (props.order.description) {
                    updatedOrder.description = props.order.description;
                } else if (props.company.defaultOrderConfirmationDescription) {
                    updatedOrder.description = props.company.defaultCancellationInvoiceDescription
                }
                if (props.order.endDescription) {
                    updatedOrder.endDescription = props.order.endDescription;
                } else if (props.company.defaultOrderConfirmationEndDescription) {
                    updatedOrder.endDescription = props.company.defaultCancellationInvoiceEndDescription
                }
                for (let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    for (let j = 0; j < props.order.documentPositions.length; j++) {
                        if (updatedOrder.documentPositions[i].id === props.order.documentPositions[j].id) {
                            updatedOrder.documentPositions[i].originalInventoryCount = props.order.documentPositions[j].inventoryCount;
                            updatedOrder.documentPositions[i].inventoryCount = -props.order.documentPositions[j].inventoryCountInvoice;
                            if (updatedOrder.documentPositions[i].inventoryCount < 0) {
                                updatedOrder.documentPositions[i].active = true;
                            } else {
                                updatedOrder.documentPositions[i].inventoryCount = 0;
                                updatedOrder.documentPositions[i].active = false;
                            }
                            break;
                        }
                    }
                }
                break;
        }
        if(updatedOrder.assignedCustomer && updatedOrder.assignedCustomer.addresses) {
            for(let i = 0; i < updatedOrder.assignedCustomer.addresses.length; i++) {
                if(updatedOrder.assignedCustomer.addresses[i].kind === e.target.value) {
                    updatedOrder.address = updatedOrder.assignedCustomer.addresses[i];
                    break;
                }
            }
            if(!updatedOrder.address) {
                for(let i = 0; i < updatedOrder.assignedCustomer.addresses.length; i++) {
                    if(updatedOrder.assignedCustomer.addresses[i].kind === "STANDARD") {
                        updatedOrder.address = updatedOrder.assignedCustomer.addresses[i];
                        break;
                    }
                }
            }
            if(!updatedOrder.address) {
                updatedOrder.address = updatedOrder.assignedCustomer.addresses[0];
            }
        }

        if(updatedOrder.assignedSupplier && updatedOrder.assignedSupplier.addresses) {
            for(let i = 0; i < updatedOrder.assignedSupplier.addresses.length; i++) {
                if(updatedOrder.assignedSupplier.addresses[i].kind === e.target.value) {
                    updatedOrder.address = updatedOrder.assignedSupplier.addresses[i];
                    break;
                }
            }
            if(!updatedOrder.address) {
                for(let i = 0; i < updatedOrder.assignedSupplier.addresses.length; i++) {
                    if(updatedOrder.assignedSupplier.addresses[i].kind === "STANDARD") {
                        updatedOrder.address = updatedOrder.assignedSupplier.addresses[i];
                        break;
                    }
                }
            }
            if(!updatedOrder.address) {
                updatedOrder.address = updatedOrder.assignedSupplier.addresses[0];
            }
        }

        console.log(updatedOrder.address);

        let activeCount = 0;
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            if(updatedOrder.documentPositions[i].active) {
                activeCount++;
                updatedOrder.documentPositions[i].displayedPosition = activeCount;
            }else{
                updatedOrder.documentPositions[i].displayedPosition = null;
            }
        }
        setOrder(updatedOrder);
        setDocumentKind(e.target.value);
    }

    const firstPositionOnClickHandler = () => {
        if(order.documentPositions && order.documentPositions.length && order.documentPositions.length > 0) {
            setPositionIndex(0);
        }
    }

    const prevPositionOnClickHandler = () => {
        if(positionIndex > 0) {
            setPositionIndex(positionIndex - 1);
        }
    }

    const nextPositionOnClickHandler = () => {
        if(positionIndex < order.documentPositions.length - 1) {
            setPositionIndex(positionIndex + 1);
        }
    }

    const lastPositionOnClickHandler = () => {
        if(order.documentPositions && order.documentPositions.length && order.documentPositions.length > 0) {
            setPositionIndex(order.documentPositions.length - 1);
        }
    }

    const documentDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, documentDate: e.target.value };
        setOrder(updatedOrder);
    }

    const descriptionOnChangeHandler = (e) => {
        const updatedOrder = { ...order, description: e.target.value };
        setOrder(updatedOrder);
    }

    const endDescriptionOnChangeHandler = (e) => {
        const updatedOrder = { ...order, endDescription: e.target.value };
        setOrder(updatedOrder);
    }

    const documentNameOnChangeHandler = (e) => {
        const updatedOrder = { ...order, documentName: e.target.value };
        setOrder(updatedOrder);
    }

    const invoiceNrOnChangeHandler = (e) => {
        const updatedOrder = { ...order, invoiceNr: e.target.value };
        setOrder(updatedOrder);
    }

    const cancellationInvoiceNrOnChangeHandler = (value) => {
        const updatedOrder = { ...order, cancellationInvoiceNr: value.documentNr, cancellationInvoiceDate: value.documentDate };
        setOrder(updatedOrder);
    }

    const cancellationInvoiceDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, cancellationInvoiceDate: e.target.value };
        setOrder(updatedOrder);
    }

    const deliveryBillNrOnChangeHandler = (e) => {
        const updatedOrder = { ...order, deliveryBillNr: e.target.value };
        setOrder(updatedOrder);
    }

    const offerNrOnChangeHandler = (e) => {
        const updatedOrder = { ...order, offerNr: e.target.value };
        setOrder(updatedOrder);
    }

    const invoiceDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, invoiceDate: e.target.value };
        setOrder(updatedOrder);
    }

    const paymentTargetOnChangeHandler = (e) => {
        const updatedOrder = { ...order, paymentTarget: e.target.value };
        setOrder(updatedOrder);
    }

    const deliveryDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, deliveryDate: e.target.value };
        setOrder(updatedOrder);
    }

    const deliveryBillDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, deliveryBillDate: e.target.value };
        setOrder(updatedOrder);
    }

    const deliveryDateAnticipatedOnChangeHandler = () => {
        const updatedOrder = { ...order, deliveryDateAnticipated: !order.deliveryDateAnticipated };
        setOrder(updatedOrder);
    }

    const noVatOnChangeHandler = () => {
        const updatedOrder = { ...order, noVat: !order.noVat };
        setOrder(updatedOrder);
    }

    const toggleAllCheck = () => {
        const updatedOrder = { ...order };
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            updatedOrder.documentPositions[i].active = !allCheck;
        }
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedOrder.documentPositions)
        });
        setAllCheck(!allCheck);
    }

    const toggleActive = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].active = !updatedOrder.documentPositions[index].active;
        let activeCount = 0;
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            if(updatedOrder.documentPositions[i].active) {
                activeCount++;
                updatedOrder.documentPositions[i].displayedPosition = activeCount;
            }else{
                updatedOrder.documentPositions[i].displayedPosition = null;
            }
        }
        setOrder(updatedOrder);
    }

    const sortedDocumentPositions = (documentPositions) => {
        let sortedAndUpdatedDocumentPositions = documentPositions.sort((a,b) => a.position - b.position);
        let activeCount = 0;
        for(let i = 0; i < sortedAndUpdatedDocumentPositions.length; i++) {
            if(sortedAndUpdatedDocumentPositions[i].active) {
                activeCount++;
                sortedAndUpdatedDocumentPositions[i].displayedPosition = activeCount;
            }else{
                sortedAndUpdatedDocumentPositions[i].displayedPosition = null;
            }
        }
        return sortedAndUpdatedDocumentPositions;
    }

    const moveDocumentPositionUp = (index) => {
        if (index <= 0 || index >= order.documentPositions.length) {
            return;
        }
        const updatedDocumentPositions = [...order.documentPositions];
        updatedDocumentPositions[index].position = updatedDocumentPositions[index].position - 1;
        updatedDocumentPositions[index - 1].position = updatedDocumentPositions[index - 1].position + 1;
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedDocumentPositions)
        });
    }

    const moveDocumentPositionDown = (index) => {
        if (index < 0 || index >= order.documentPositions.length - 1) {
            return;
        }
        let updatedDocumentPositions = [...order.documentPositions];
        updatedDocumentPositions[index].position = updatedDocumentPositions[index].position + 1;
        updatedDocumentPositions[index + 1].position = updatedDocumentPositions[index + 1].position - 1;
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedDocumentPositions)
        });
    }

    const positionNoteOnChangeHandler = (e, index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].note = e.target.value;
        setOrder(updatedOrder);
    }

    const positionDeliveryDateOnChangeHandler = (e, index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].deliveryDate = e.target.value;
        setOrder(updatedOrder);
    }

    const inventoryCountOnChangeHandler = (e, index) => {
        const updatedOrder = { ...order }
        updatedOrder.documentPositions[index].inventoryCount = e.target.value;
        setOrder(updatedOrder);
    }

    const unitOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].unit = e.target.value;
        setOrder(updatedOrder);
    }

    const piecePriceOnChange = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].piecePrice = e.target.value;
        setOrder(updatedOrder);
    }

    const positionVatOnChange = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].positionVat = e.target.value;
        setOrder(updatedOrder);
    }

    const positionDescriptionOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].description = e.target.value;
        setOrder(updatedOrder);
    }

    const positionHintOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].hint = e.target.value;
        setOrder(updatedOrder);
    }

    const removeOrderPosition = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions.splice(index,1);
        let activeCount = 0;
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            if(updatedOrder.documentPositions[i].active) {
                activeCount++;
                updatedOrder.documentPositions[i].displayedPosition = activeCount;
            }else{
                updatedOrder.documentPositions[i].displayedPosition = null;
            }
        }
        setOrder(updatedOrder);
    };

    const offerPositionOnChangeHandler = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].offerPosition = !updatedOrder.documentPositions[index].offerPosition;
        setOrder(updatedOrder);
    }

    const guidePriceOnChangeHandler = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].guidePrice = !updatedOrder.documentPositions[index].guidePrice;
        setOrder(updatedOrder);
    }

    const getNextNr = async (kind) => {
        let url = '/company';
        switch (kind) {
            case 'INVOICE':
                url = url + '/next-invoice-nr';
                break;
            case 'CANCELLATION_INVOICE':
                url = url + '/next-invoice-nr';
                break;
            case 'ORDER_CONFIRMATION':
            case 'OFFER':
                url = url + '/next-offer-nr';
                break;
            case 'DELIVERY_BILL':
                url = url + '/next-delivery-bill-nr';
                break;
            case 'COMMISSION':
                url = url + '/next-commission-nr';
                break;
            case 'CUSTOMER':
                url = url + '/next-customer-nr';
                break;
            case 'SUPPLIER':
                url = url + '/next-supplier-nr';
                break;
            case 'REQUEST':
            case 'ORDER':
                url = url + '/next-order-nr';
                break;
            default:
                props.showMessage(2,"Unbekannte Nummernart");
                return null;
        }
        try {
            const response = await api.put(url);
            return response.data;
        } catch (error) {
            if (error.response) {
                props.showMessage(2, error.response.data.message);
            } else if (error.request) {
                props.showMessage(2, "Es kann keine Verbindung zum Server aufgebaut werden");
                console.log(error.request);
            } else {
                props.showMessage(2, "Etwas ist schiefgelaufen");
                console.log(error.message);
            }
        }
        return null;
    }

    const generateDocumentOnClickHandler = (order,kind) => {
        if(validateGenerateDocument(order,kind)) {
            if(checkGenerateDocumentPositionSums(order,kind)) {
                executeGenerateDocument(order,kind);
            }else{
                showGenerateDocumentWarningDialog();
            }
        }
    }

    const validateGenerateDocument = (order, kind) => {
        console.log(order);
        if(!order.address) {
            props.showMessage(2,"Es wurde keine Adresse ausgewählt");
            return false;
        }
        if(!order.documentDate) {
            order.documentDate = new Date().toISOString().substring(0,10);
        }
        for(let i = 0; i < order.documentPositions.length; i++) {
            if(order.documentPositions[i].active && (!order.documentPositions[i].positionVat || !order.documentPositions[i].article || !order.documentPositions[i].article.name || order.documentPositions[i].inventoryCount === null)) {
                props.showMessage(2,"Fehlende oder falsche Werte");
                return false;
            }
        }
        if(kind === "CANCELLATION_INVOICE") {
            if(!order.cancellationInvoiceNr || !order.cancellationInvoiceDate) {
                props.showMessage(2,"Fehlende Stornorechnungsnummer oder -datum");
                return false;
            }
            for(let i = 0; i < order.documentPositions.length; i++) {
                if(order.documentPositions[i].active && order.documentPositions[i].inventoryCount > 0) {
                    props.showMessage(2,"Bei einer Stornorechnungen muss die Anzahl aller Positionen kleiner gleich 0 sein");
                    return false;
                }
            }
        }
        return true;
    }

    const checkGenerateDocumentPositionSums = (order, kind) => {
        if(kind === "INVOICE") {
            for(let i = 0; i < order.documentPositions.length; i++) {
                for(let j = 0; j < props.order.documentPositions.length; j++) {
                    if(order.documentPositions[i].id === props.order.documentPositions[j].id) {
                        if(parseFloat(props.order.documentPositions[j].inventoryCount) < parseFloat(order.documentPositions[i].inventoryCount) + parseFloat(props.order.documentPositions[j].inventoryCountInvoice)) {
                            return false;
                        }
                        break;
                    }
                }
            }
        }else if(kind === "DELIVERY_BILL") {
            for(let i = 0; i < order.documentPositions.length; i++) {
                for(let j = 0; j < props.order.documentPositions.length; j++) {
                    if(order.documentPositions[i].id === props.order.documentPositions[j].id) {
                        if(parseFloat(props.order.documentPositions[j].inventoryCount) < parseFloat(order.documentPositions[i].inventoryCount) + parseFloat(props.order.documentPositions[j].inventoryCountDeliveryBill)) {
                            return false;
                        }
                        break;
                    }
                }
            }
        }
        return true;
    }

    const executeGenerateDocument = (order, kind) => {
        props.setLoading(true);
        try{
            switch (kind) {
                case "INVOICE":
                    if(!order.invoiceNr) {
                        getNextNr(kind).then(nr => {
                            order.invoiceNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order,setOrder);
                        });
                    }else{
                        order.documentNr = order.invoiceNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order,setOrder);
                    }
                    break;
                case "CANCELLATION_INVOICE":
                    if(!order.invoiceNr) {
                        getNextNr(kind).then(nr => {
                            order.invoiceNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order);
                        });
                    }else{
                        order.documentNr = order.invoiceNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order);
                    }
                    break;
                case "DELIVERY_BILL":
                    if(!order.deliveryBillNr) {
                        getNextNr(kind).then(nr => {
                            order.deliveryBillNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order,setOrder);
                        });
                    }else{
                        order.documentNr = order.deliveryBillNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order,setOrder);
                    }
                    break;
                case "ORDER_CONFIRMATION":
                    if(!order.offerNr) {
                        getNextNr(kind).then(nr => {
                            order.offerNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order);
                        });
                    }else{
                        order.documentNr = order.offerNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order);
                    }
                    break;
                case "OFFER":
                    if(!order.offerNr) {
                        getNextNr(kind).then(nr => {
                            order.offerNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order);
                        });
                    }else{
                        order.documentNr = order.offerNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order);
                    }
                    break;
                case "REQUEST":
                    if(!order.orderNr) {
                        getNextNr(kind).then(nr => {
                            order.orderNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order);
                        });
                    }else{
                        order.documentNr = order.orderNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order);
                    }
                    break;
                case "ORDER":
                    if(!order.orderNr) {
                        getNextNr(kind).then(nr => {
                            order.orderNr = nr;
                            order.documentNr = nr;
                            props.addFileToOrder(generateDocument(order,kind), kind, order);
                        });
                    }else{
                        order.documentNr = order.orderNr;
                        props.addFileToOrder(generateDocument(order,kind), kind, order);
                    }
                    break;
            }
        }catch (exception){
            props.setLoading(false);
            props.showMessage(2,"Das Dokument konnte nicht generiert werden.");
            console.log(exception)
        }
    }

    const MAX_LINE_LENGTH = 85;
    const MAX_SMALL_LINE_LENGTH = 45;
    const POSITIONS_START_Y = 230;
    const LINE_HEIGHT = 8;
    const FORCE_PAGE_BREAK_LINES = 27;
    const PAGE_LINES = 40;
    const LEFT_BORDER = 40;
    const ARTICLE_X = 60;
    const COUNT_X = 240;
    const PIECE_PRICE_X = 290;
    const SUM_PRICE_X = 340;
    const EXTRA_INFO_X = 370;
    const LINE_OFFSET = 4;
    const LOWER_CONTENT_START_LINE = 29;
    const SUM_PRICE_TEXT_X = 270;

    const generateDocument = (rawOrder, kind) => {
        let order = rawOrder;

        for (let i = order.documentPositions.length - 1; i >= 0; i--) {
            if(order.documentPositions[i].active) {
                if(order.documentPositions[i].article && order.documentPositions[i].article.name) {
                    let articleText = order.documentPositions[i].article.name;
                    order.documentPositions[i].formattedArticleText = [];
                    do{
                        if(articleText.length > MAX_SMALL_LINE_LENGTH) {
                            order.documentPositions[i].formattedArticleText.push(articleText.substring(0,articleText.substring(0,MAX_SMALL_LINE_LENGTH).lastIndexOf(" ")).trim());
                            articleText = articleText.substring(articleText.substring(0,MAX_SMALL_LINE_LENGTH).lastIndexOf(" ")).trim();
                        }else{
                            order.documentPositions[i].formattedArticleText.push(articleText.trim());
                            articleText = "";
                        }
                    }while(articleText.length > 0)
                }
                if (order.documentPositions[i].description) {
                    order.documentPositions[i].formattedDescription = order.documentPositions[i].description.split('\n');
                    for(let j = 0; j < order.documentPositions[i].formattedDescription.length; j++) {
                        if(order.documentPositions[i].formattedDescription[j].length > MAX_LINE_LENGTH) {
                            order.documentPositions[i].formattedDescription.splice(j + 1,0,order.documentPositions[i].formattedDescription[j].substring(MAX_LINE_LENGTH).trim())
                            order.documentPositions[i].formattedDescription[j] = order.documentPositions[i].formattedDescription[j].substring(0,MAX_LINE_LENGTH);
                        }
                    }
                } else {
                    order.documentPositions[i].formattedDescription = null;
                }
                if (order.documentPositions[i].hint) {
                    order.documentPositions[i].formattedHint = order.documentPositions[i].hint.split('\n');
                    for(let j = 0; j < order.documentPositions[i].formattedHint.length; j++) {
                        if(order.documentPositions[i].formattedHint[j].length > MAX_LINE_LENGTH) {
                            order.documentPositions[i].formattedHint.splice(j + 1,0,order.documentPositions[i].formattedHint[j].substring(MAX_LINE_LENGTH).trim())
                            order.documentPositions[i].formattedHint[j] = order.documentPositions[i].formattedHint[j].substring(0,MAX_LINE_LENGTH);
                        }
                    }
                } else {
                    order.documentPositions[i].formattedHint = null;
                }
                if (order.noVat) {
                    order.documentPositions[i].positionVat = 0;
                }
            }
        }

        //format: ~445 x 630
        let pdf = new jsPDF('p', 'px');
        pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular-normal', 'normal');
        pdf.setFont('OpenSans-Regular');

        let availableLines = PAGE_LINES;
        let usedLines = 0;

        initializeCommonDocumentData(order, pdf, true, true, kind);

        let guidePriceExists = false;

        if (order.description) {
            order.formattedDescription = order.description.split('\n');
            for(let i = 0; i < order.formattedDescription.length; i++) {
                if(order.formattedDescription[i].length > MAX_LINE_LENGTH) {
                    order.formattedDescription.splice(i + 1,0,order.formattedDescription[i].substring(MAX_LINE_LENGTH).trim())
                    order.formattedDescription[i] = order.formattedDescription[i].substring(0,MAX_LINE_LENGTH);
                }
            }
        }

        if(order.formattedDescription) {
            for (let i = 0; i < order.formattedDescription.length; i++) {
                if ((availableLines - usedLines) > 3) {
                    pdf.text(order.formattedDescription[i], LEFT_BORDER, POSITIONS_START_Y + ((usedLines) * LINE_HEIGHT));
                    usedLines++;
                } else {
                    initializeCommonDocumentData(order, pdf, false, false, kind);
                    usedLines = 0;
                    pdf.text(order.formattedDescription[i], LEFT_BORDER, POSITIONS_START_Y + ((usedLines) * LINE_HEIGHT));
                    usedLines++;
                }
            }
            usedLines++;
        }

        pdf.text("Pos", LEFT_BORDER, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
        pdf.text("Artikel", ARTICLE_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
        pdf.text("Menge", COUNT_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
        if(kind !== "DELIVERY_BILL" && kind !== "REQUEST") {
            pdf.text("Stk. Preis", PIECE_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
            pdf.text("Gesamt", SUM_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
        }
        usedLines += 2;

        for (let i = 0; i < order.documentPositions.length; i++) {
            if(order.documentPositions[i].active) {

                // Prepare texts
                let pieceNetPriceRounded = HelperFunctions.round((order.documentPositions[i].piecePrice), 2);
                let positionNetPriceRounded = HelperFunctions.round(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount, 2);

                // New Page
                if ((order.documentPositions[i].formattedDescription && (availableLines - usedLines) < 3) || (!order.documentPositions[i].formattedDescription && (availableLines - usedLines) < 2)) {
                    initializeCommonDocumentData(order, pdf, false, false, kind);
                    usedLines = 2;
                }

                // New Page
                if ((order.documentPositions[i].formattedHint && (availableLines - usedLines) < 3) || (!order.documentPositions[i].formattedHint && (availableLines - usedLines) < 2)) {
                    initializeCommonDocumentData(order, pdf, false, false, kind);
                    usedLines = 2;
                }

                pdf.text("____________________________________________________________________________________________________", LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines) - LINE_OFFSET);
                usedLines++;
                pdf.text(order.documentPositions[i].displayedPosition.toString(), LEFT_BORDER, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                let inventoryCountString = order.documentPositions[i].inventoryCount;
                if(order.documentPositions[i].unit) {
                    inventoryCountString = inventoryCountString + " " + order.documentPositions[i].unit;
                }
                pdf.text(inventoryCountString, COUNT_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                if(kind !== "DELIVERY_BILL" && kind !== "REQUEST") {
                    let pieceNetPriceRoundedText = HelperFunctions.formatCurrencyAmount(pieceNetPriceRounded);
                    if(order.documentPositions[i].guidePrice && documentKind === "OFFER") {
                        pieceNetPriceRoundedText = "ca. " + pieceNetPriceRoundedText;
                        guidePriceExists = true;
                    }
                    pdf.text(pieceNetPriceRoundedText, PIECE_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                    pdf.text(HelperFunctions.formatCurrencyAmount(positionNetPriceRounded), SUM_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);

                    if (kind === "OFFER" && order.documentPositions[i].offerPosition) {
                        pdf.text("Alternativposition", EXTRA_INFO_X, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                    }
                }

                if(order.documentPositions[i].formattedArticleText) {
                    for (let j = 0; j < order.documentPositions[i].formattedArticleText.length; j++) {
                        if ((availableLines - usedLines) > 0) {
                            pdf.text(order.documentPositions[i].formattedArticleText[j], ARTICLE_X, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        } else {
                            // New Page
                            initializeCommonDocumentData(order, pdf, false, false, kind);
                            usedLines = 2;
                            pdf.setTextColor("gray");
                            pdf.text(order.documentPositions[i].formattedArticleText[j], LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        }
                    }
                }

                if (order.documentPositions[i].deliveryDate) {
                    pdf.setTextColor("gray");
                    let deliveryDate = "Abweichendes Lieferdatum: " + helperFunctions.formatDate(order.documentPositions[i].deliveryDate);
                    pdf.text(deliveryDate.toString(), LEFT_BORDER, POSITIONS_START_Y + LINE_HEIGHT * usedLines);
                    pdf.setTextColor("black");
                    usedLines++;
                }

                if (order.documentPositions[i].formattedDescription) {
                    pdf.setTextColor("gray");
                    for (let j = 0; j < order.documentPositions[i].formattedDescription.length; j++) {
                        if ((availableLines - usedLines) > 0) {
                            pdf.text(order.documentPositions[i].formattedDescription[j], LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        } else {
                            // New Page
                            initializeCommonDocumentData(order, pdf, false, false, kind);
                            usedLines = 2;
                            pdf.setTextColor("gray");
                            pdf.text(order.documentPositions[i].formattedDescription[j], LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        }
                    }
                    pdf.setTextColor("black");
                }
                if (order.documentPositions[i].formattedHint) {
                    pdf.setTextColor("red");
                    for (let j = 0; j < order.documentPositions[i].formattedHint.length; j++) {
                        if ((availableLines - usedLines) > 0) {
                            pdf.text(order.documentPositions[i].formattedHint[j], LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        } else {
                            // New Page
                            initializeCommonDocumentData(order, pdf, false, false, kind);
                            usedLines = 0;

                            usedLines += 2
                            pdf.setTextColor("gray");
                            pdf.text(order.documentPositions[i].formattedHint[j], LEFT_BORDER, POSITIONS_START_Y + (LINE_HEIGHT * usedLines));
                            usedLines++;
                        }
                    }
                    pdf.setTextColor("black");
                }
            }
        }

        let vats = [];

        if(usedLines > FORCE_PAGE_BREAK_LINES) {
            initializeCommonDocumentData(order, pdf, false, false, kind);
            usedLines = 0;
            pdf.setTextColor("black");
        }

        if(kind !== "DELIVERY_BILL" && kind !== "REQUEST") {
            // Price Sum

            let netSum = 0;
            let sum = 0;
            for (let i = 0; i < order.documentPositions.length; i++) {
                if (order.documentPositions[i].active && (kind !== "OFFER" || !order.documentPositions[i].offerPosition)) {
                    netSum = netSum + parseFloat(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount);
                    let knownVat = -1;
                    for (let l = 0; l < vats.length; l++) {
                        if (vats[l].percentage === order.documentPositions[i].positionVat) {
                            knownVat = l;
                        }
                    }
                    if (knownVat !== -1) {
                        vats[knownVat].sum = parseFloat(vats[knownVat].sum) + parseFloat(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * order.documentPositions[i].positionVat / 100);
                    } else {
                        vats.push({
                            sum: parseFloat(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * order.documentPositions[i].positionVat / 100),
                            percentage: order.documentPositions[i].positionVat
                        });
                    }
                }
            }
            sum = netSum;

            let netSumText = HelperFunctions.formatCurrencyAmount(HelperFunctions.round(netSum, 2));
            if(documentKind === "OFFER" && guidePriceExists) {
                netSumText = "ca. " + netSumText;
            }

            pdf.text('Summe netto', SUM_PRICE_TEXT_X, POSITIONS_START_Y + LINE_HEIGHT * LOWER_CONTENT_START_LINE);
            pdf.text(netSumText, SUM_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * LOWER_CONTENT_START_LINE);

            for (let i = 0; i < vats.length; i++) {
                pdf.text('MwSt. ' + vats[i].percentage + ' %', SUM_PRICE_TEXT_X, POSITIONS_START_Y + (LOWER_CONTENT_START_LINE + (i + 1)) * LINE_HEIGHT);

                let vatSum = HelperFunctions.round(vats[i].sum, 2);

                let vatsSumText = HelperFunctions.formatCurrencyAmount(vatSum);
                if(documentKind === "OFFER" && guidePriceExists) {
                    vatsSumText = "ca. " + vatsSumText;
                }

                pdf.text(vatsSumText, SUM_PRICE_X, POSITIONS_START_Y + (LOWER_CONTENT_START_LINE + (i + 1)) * LINE_HEIGHT);
                sum = sum + vatSum;
            }

            pdf.setFont('OpenSans-Bold', 'normal');

            let sumText = HelperFunctions.formatCurrencyAmount(sum);
            if(documentKind === "OFFER" && guidePriceExists) {
                sumText = "ca. " + sumText;
            }

            pdf.text('Gesamt', SUM_PRICE_TEXT_X, POSITIONS_START_Y + LINE_HEIGHT * LOWER_CONTENT_START_LINE + ((vats.length + 1) * LINE_HEIGHT));
            pdf.text(sumText, SUM_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT * LOWER_CONTENT_START_LINE + ((vats.length + 1) * LINE_HEIGHT));
            pdf.text('========================', SUM_PRICE_TEXT_X, POSITIONS_START_Y + LINE_HEIGHT * LOWER_CONTENT_START_LINE + ((vats.length + 2) * LINE_HEIGHT));
        }

        pdf.setFont('OpenSans-Regular', 'normal');

        let usedLowerContentLines = LOWER_CONTENT_START_LINE + vats.length + 4;
        const END_DESCRIPTION_END_LINES = 40;

        if (order.endDescription) {
            order.formattedEndDescription = order.endDescription.split('\n');
            for(let i = 0; i < order.formattedEndDescription.length; i++) {
                if(order.formattedEndDescription[i].length > MAX_LINE_LENGTH) {
                    order.formattedEndDescription.splice(i + 1,0,order.formattedEndDescription[i].substring(MAX_LINE_LENGTH).trim())
                    order.formattedEndDescription[i] = order.formattedEndDescription[i].substring(0,MAX_LINE_LENGTH);
                }
            }
        }

        console.log(order.formattedEndDescription);

        if(order.formattedEndDescription) {
            for (let i = 0; i < order.formattedEndDescription.length; i++) {
                if (END_DESCRIPTION_END_LINES >= usedLowerContentLines) {
                    pdf.text(order.formattedEndDescription[i], LEFT_BORDER, POSITIONS_START_Y + ((usedLowerContentLines) * LINE_HEIGHT));
                    usedLowerContentLines++;
                } else {
                    initializeCommonDocumentData(order, pdf, false, true, kind);
                    usedLowerContentLines = 0;
                    pdf.text(order.formattedEndDescription[i], LEFT_BORDER, POSITIONS_START_Y + ((usedLowerContentLines) * LINE_HEIGHT));
                    usedLowerContentLines++;
                }
            }
        }

        let fileName;

        switch (kind) {
            case "INVOICE":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_R' + order.invoiceNr;
                } else {
                    fileName = 'rechnung_R' + order.invoiceNr;
                }
                break;
            case "CANCELLATION_INVOICE":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_R' + order.invoiceNr;
                } else {
                    fileName = 'stornorechnung_R' + order.invoiceNr;
                }
                break;
            case "OFFER":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_A' + order.offerNr;
                } else {
                    fileName = 'angebot_A' + order.offerNr;
                }
                break;
            case "DELIVERY_BILL":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_LS' + order.deliveryBillNr;
                } else {
                    fileName = 'lieferschein_LS' + order.deliveryBillNr;
                }
                break;
            case "ORDER_CONFIRMATION":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_A' + order.offerNr;
                } else {
                    fileName = 'auftragsbestätigung_A' + order.offerNr;
                }
                break;
            case "ORDER":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_B' + order.orderNr;
                } else {
                    fileName = 'bestellung_B' + order.orderNr;
                }
                break;
            case "REQUEST":
                if (order.documentName) {
                    fileName = order.documentName.toLowerCase() + '_B' + order.orderNr;
                } else {
                    fileName = 'anfrage_A' + order.orderNr;
                }
                break;
            default:
                fileName = "dokument"
                break;
        }

        if(order.assignedCustomer) {
            if(order.assignedCustomer.nr) {
                fileName = fileName + "_" + order.assignedCustomer.nr;
            }
            if(order.assignedCustomer.name) {
                fileName = fileName + "_" + order.assignedCustomer.name;
            }
        }

        if(order.assignedSupplier) {
            if(order.assignedSupplier.nr) {
                fileName = fileName + "_" + order.assignedSupplier.nr;
            }
            if(order.assignedSupplier.name) {
                fileName = fileName + "_" + order.assignedSupplier.name;
            }
        }

        if(order.orderNr && kind !== "ORDER" && kind !== "REQUEST") {
            fileName = fileName + "_bestellnr_B" + order.orderNr;
        }else if(order.orderNr && kind === "REQUEST") {
            fileName = fileName + "_anfragenr_A" + order.orderNr;
        }else if(order.orderNr && kind === "ORDER") {
            fileName = fileName + "_bestellnr_B" + order.orderNr;
        }

        if((kind === "ORDER" || kind === "REQUEST") && order.customerOrders && order.customerOrders.length > 0) {
            fileName = fileName + "_" + order.customerOrders[0].commissionNr + "_" + order.customerOrders[0].assignedCustomer.name;
        }

        fileName = fileName + ".pdf";

        setLastGeneratedOrder(order);
        if(kind === "DELIVERY_BILL") {
            showOvertakeInformationDialog();
        }

        // set overall page numbers
        let overallPages = pdf.getNumberOfPages();
        for (let i = 1; i <= overallPages; i++) {
            pdf.setPage(i);
            pdf.text("Seite " + i + " von " + overallPages, 380,620); // Add content or manipulate the page
        }

        return helperFunctions.dataURLtoFile(pdf.output('datauristring'), fileName);
    }

    const initializeCommonDocumentData = (order,pdf,firstPage,noHeader,kind) => {
        if(!firstPage) {
            pdf.addPage('string','p');
        }
        pdf.setTextColor("black");
        pdf.setFontSize(8);

        if(props.company.logo && props.company.logoWidth && props.company.logoHeight) {
            let dimensions = {
                width:props.company.logoWidth,
                height:props.company.logoHeight
            }
            let calculatedLogoDimensions = HelperFunctions.calculateLogoDimensions(dimensions,435,90)
            let x = (445 / 2) - (calculatedLogoDimensions.width / 2);
            pdf.addImage(props.company.logo, x, 5, calculatedLogoDimensions.width, calculatedLogoDimensions.height);
        }

            // Company Strings

        let companyName = props.company.name ? props.company.name : '';
        let companyStreetName = props.company.streetName ? props.company.streetName : '';
        let companyStreetNr = props.company.streetNr ? props.company.streetNr : '';
        let companyAddressSupplement = props.company.addressSupplement ? props.company.addressSupplement : '';
        let companyPostalCode = props.company.postalCode ? props.company.postalCode : '';
        let companyCity = props.company.city ? props.company.city : '';
        let companyCountry = props.company.country ? props.company.country : '';
        let companyWebsite = props.company.website ? props.company.website : '';
        let companyEmail = props.company.email ? props.company.email : '';
        let companyChiefExecutiveOfficer = props.company.chiefExecutiveOfficer ? props.company.chiefExecutiveOfficer : '';
        let companyPhoneNumber = props.company.phoneNumber ? props.company.phoneNumber : '';
        let companyBic = props.company.bic ? props.company.bic : '';
        let companyIban = props.company.iban ? props.company.iban : '';
        let companyTaxId = props.company.taxId ? props.company.taxId : '';
        let companyTaxNr = props.company.taxNr ? props.company.taxNr : '';
        let companyCompanyRegistrationNumber = props.company.companyRegistrationNumber ? props.company.companyRegistrationNumber : '';
        let companyCommercialCourtLocation = props.company.commercialCourtLocation ? props.company.commercialCourtLocation : '';

        let companyStreetNameAndNrAndAddressSupplement = companyStreetName + ' ' + companyStreetNr + ' ' + companyAddressSupplement;
        let companyPostalCodeAndCity = companyPostalCode + ' ' + companyCity;

        let companyInfoHeadline = companyName + ' - ' + companyStreetName + ' ' + companyStreetNr + ' ' + companyAddressSupplement + ' - ' + companyPostalCode + ' ' + companyCity;

        // Customer Strings
        let assignedBusinessPartnerName = "";

        if(kind === "INVOICE" || kind === "DELIVERY_BILL" || kind === "CANCELLATION_INVOICE" || kind === "FACTORY_CERTIFICATE" || kind === "ORDER_CONFIRMATION" || kind === "OTHER" || kind === "OFFER") {
            assignedBusinessPartnerName = order.assignedCustomer.name && order.assignedCustomer.name ? order.assignedCustomer.name : '';
        }else if(kind === "ORDER" || kind === "REQUEST") {
            assignedBusinessPartnerName = order.assignedSupplier.name && order.assignedSupplier.name ? order.assignedSupplier.name : '';
        }

        let assignedBusinessPartnerStreetName = "";
        let assignedBusinessPartnerStreetNr = "";
        let assignedBusinessPartnerAddressSupplement = "";
        let assignedBusinessPartnerPostalCode = "";
        let assignedBusinessPartnerCity = "";
        let assignedBusinessPartnerCountry = "";

        if(order.address) {
            assignedBusinessPartnerStreetName = order.address.streetName ? order.address.streetName : "";
            assignedBusinessPartnerStreetNr = order.address.streetNr ? order.address.streetNr : "";
            assignedBusinessPartnerAddressSupplement = order.address.supplement ? order.address.supplement : "";
            assignedBusinessPartnerPostalCode = order.address.postalCode ? order.address.postalCode : "";
            assignedBusinessPartnerCity = order.address.city ? order.address.city : "";
            assignedBusinessPartnerCountry = order.address.country ? order.address.country : "";
        }

        let assignedBusinessPartnerStreetNameAndNr = assignedBusinessPartnerStreetName + ' ' + assignedBusinessPartnerStreetNr;
        let assignedBusinessPartnerPostalCodeAndCity = assignedBusinessPartnerPostalCode + ' ' + assignedBusinessPartnerCity;

        pdf.text(companyInfoHeadline,40,120);
        pdf.setFontSize(10);
        pdf.text(assignedBusinessPartnerName,40,130);
        let streetWithSupplement = assignedBusinessPartnerStreetNameAndNr;
        if(assignedBusinessPartnerAddressSupplement !== '') {
            streetWithSupplement = assignedBusinessPartnerStreetNameAndNr + " (" + assignedBusinessPartnerAddressSupplement + ")";
        }
        pdf.text(streetWithSupplement,40,140);
        pdf.text(assignedBusinessPartnerPostalCodeAndCity,40,150);
        pdf.text(assignedBusinessPartnerCountry,40,160);

        pdf.setFontSize(12);
        if(order.orderNr) {
            if(order.assignedCustomer || (order.assignedSupplier && kind === "ORDER")) {
                pdf.text('Bestellnr.: B' + order.orderNr,300,110);
            }else if(order.assignedSupplier && kind === "REQUEST") {
                pdf.text('Anfragenr.: A' + order.orderNr,300,110);
            }
        }
        if(order.invoiceNr) {
            pdf.text('Rechnungsnr.: R' + order.invoiceNr,300,122);
        }

        let deliveryDate = order.deliveryDate ? HelperFunctions.dateToString(order.deliveryDate) : '';

        pdf.setFontSize(8);

        if(order.assignedCustomer && order.assignedCustomer.nr) {
            pdf.text("Kunden-Nr.: " + order.assignedCustomer.nr,300,132);
        }else if(order.assignedSupplier && order.assignedSupplier.nr) {
            pdf.text("Lieferant.-Nr.: " + order.assignedSupplier.nr,300,132);
        }

        if(order.commissionNr) {
            let commissionNrAndItemText = "Komm-Nr.: " + order.commissionNr;
            if(order.item) {
                commissionNrAndItemText = commissionNrAndItemText + "/" + order.item;
            }
            pdf.text(commissionNrAndItemText,300,140);
        }

        if(kind === "INVOICE" && !order.invoiceDate) {
            order.invoiceDate = order.documentDate;
        }

        if(order.invoiceDate) {
            let invoiceDateLabel = 'Rechnungsdatum: ' + HelperFunctions.formatDate(order.invoiceDate);
            pdf.text(invoiceDateLabel,300,148);
        }

        if(order.invoiceDate && order.paymentTarget) {
            let paymentTargetAsDate = new Date(order.invoiceDate);
            let paymentTargetAsString = '';
            paymentTargetAsDate.setDate(paymentTargetAsDate.getDate() + order.paymentTarget);
            paymentTargetAsString = HelperFunctions.dateToString(paymentTargetAsDate.toISOString()) + " (" + order.paymentTarget + " Tage)";
            pdf.text(order.paymentTarget ? 'Zahlungsziel: ' + paymentTargetAsString : '',300,164);
        }
        if(order.deliveryDate) {
            let deliveryDateLabel = 'Lieferdatum: ' + deliveryDate;
            for(let i = 0; i < order.documentPositions.length; i++) {
                if(order.documentPositions[i].active) {
                    if(order.documentPositions[i].deliveryDate) {
                        deliveryDateLabel += ' (abw. Pos.)'
                        break;
                    }
                }
            }
            if(order.deliveryDateAnticipated) {
                deliveryDateLabel = deliveryDateLabel + " (u. Vorb.)";
            }
            pdf.text(deliveryDateLabel,300,156);
        }
        if(props.company) {
            if(props.company.defaultContactPerson) {
                pdf.text('Ansprechpartner: ' + props.company.defaultContactPerson,300,172);
            }
            if(props.company.defaultContactEmail) {
                pdf.text('E-Mail: ' + props.company.defaultContactEmail,300,180);
            }
            if(props.company.defaultContactPhoneNumber) {
                pdf.text('Telefonnummer: ' + props.company.defaultContactPhoneNumber,300,188);
            }
        }

        pdf.setFontSize(16);
        pdf.setFont('OpenSans-Bold','normal');
        let headerText = "";
        switch (kind) {
            case "INVOICE":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Rechnung";
                }
                if(order.invoiceNr) {
                    headerText = headerText + " R" + order.invoiceNr;
                }
                break;
            case "CANCELLATION_INVOICE":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Stornorechnung";
                }
                if(order.invoiceNr) {
                    headerText = headerText + " R" + order.invoiceNr;
                }
                break;
            case "OFFER":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Angebot";
                }
                if(order.offerNr) {
                    headerText = headerText + " A" + order.offerNr;
                }
                break;
            case "DELIVERY_BILL":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Lieferschein";
                }
                if(order.deliveryBillNr) {
                    headerText = headerText + " LS" + order.deliveryBillNr;
                }
                break;
            case "ORDER_CONFIRMATION":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Auftragsbestätigung";
                }
                if(order.offerNr) {
                    headerText = headerText + " A" + order.offerNr;
                }
                break;
            case "ORDER":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Bestellung";
                }
                if(order.orderNr) {
                    headerText = headerText + " B" + order.orderNr;
                }
                break;
            case "REQUEST":
                if(order.documentName) {
                    headerText = order.documentName;
                }else{
                    headerText = "Anfrage";
                }
                if(order.orderNr) {
                    headerText = headerText + " A" + order.orderNr;
                }
                break;
            default:
                break;
        }
        pdf.text(headerText,40,205);

        pdf.setFont('OpenSans-Regular','normal');
        pdf.setFontSize(10);
        pdf.setTextColor("black");

        if(!noHeader) {
            pdf.text("Pos", LEFT_BORDER, POSITIONS_START_Y + LINE_HEIGHT);
            pdf.text("Artikel", ARTICLE_X, POSITIONS_START_Y + LINE_HEIGHT);
            pdf.text("Menge", COUNT_X, POSITIONS_START_Y + LINE_HEIGHT);
            if(kind !== "DELIVERY_BILL" || kind !== "REQUEST") {
                pdf.text("Stk. Preis", PIECE_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT);
                pdf.text("Gesamt", SUM_PRICE_X, POSITIONS_START_Y + LINE_HEIGHT);
            }
        }

        if(firstPage && kind === "INVOICE" && (order.deliveryBillNr || order.deliveryBillDate)) {
            let deliveryBillText = "Lieferschein";
            if(order.deliveryBillNr) {
                deliveryBillText += " LS" + order.deliveryBillNr;
            }
            if(order.deliveryBillDate) {
                deliveryBillText += " vom " + HelperFunctions.formatDate(order.deliveryBillDate);
            }
            pdf.text(deliveryBillText,40,215);
        }

        if(firstPage && kind === "CANCELLATION_INVOICE" && (order.cancellationInvoiceNr || order.cancellationInvoiceDate)) {
            let cancellationInvoiceText = "Für die Rechnung";
            if(order.cancellationInvoiceNr) {
                cancellationInvoiceText += " R" + order.cancellationInvoiceNr;
            }
            if(order.cancellationInvoiceDate) {
                cancellationInvoiceText += " vom " + HelperFunctions.formatDate(order.cancellationInvoiceDate);
            }
            pdf.text(cancellationInvoiceText,40,215);
        }

        if(order.documentDate) {
            pdf.text(HelperFunctions.formatDate(order.documentDate),300,205);
        }

        pdf.setFontSize(8);
        pdf.setTextColor("gray");
        pdf.text(companyName,40,570);
        pdf.text(companyStreetNameAndNrAndAddressSupplement,40,578);
        pdf.text(companyPostalCodeAndCity,40,586);
        pdf.text(companyCountry,40,594);

        pdf.text(props.company.website ? 'Web: ' + companyWebsite : '',160,570);
        pdf.text(props.company.email ? 'E-Mail: ' + companyEmail : '',160,578);
        pdf.text(props.company.phoneNumber ? 'Tel.: ' + companyPhoneNumber : '',160,586);
        pdf.text(props.company.chiefExecutiveOfficer ? 'Geschäftsführung:' : '',160,594);
        pdf.text(props.company.chiefExecutiveOfficer ? companyChiefExecutiveOfficer : '',160,602);

        pdf.text(props.company.bic ? 'BLZ: ' + companyBic : '',300,570);
        pdf.text(props.company.iban ? 'IBAN: ' +  companyIban : '',300,578);
        pdf.text(props.company.taxId ? 'USt-ID: ' + companyTaxId : '',300,586);
        pdf.text(props.company.taxNr ? 'Steuernr.: ' + companyTaxNr : '',300,594);
        pdf.text(props.company.commercialCourtLocation ? 'Handelsreg. Ort: ' + companyCommercialCourtLocation : '',300,602);
        pdf.text(props.company.companyRegistrationNumber ? 'Handelsreg. Nr.: ' + companyCompanyRegistrationNumber : '',300,610);

        pdf.setFontSize(10);
        pdf.setTextColor('black');
    }

    const generateEInvoice = (order) => {
        // Create a new XML document
        let xmlDoc = document.implementation.createDocument("", "", null);

        // Create the root element (CrossIndustryInvoice)
        let root = xmlDoc.createElementNS("urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100", "rsm:CrossIndustryInvoice");
        xmlDoc.appendChild(root);

        // Define namespaces
        root.setAttribute("xmlns:rsm", "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100");
        root.setAttribute("xmlns:udt", "urn:un:unece:uncefact:data:standard:UnqualifiedDataType:100");
        root.setAttribute("xmlns:ram", "urn:un:unece:uncefact:data:standard:ReusableAggregateBusinessInformationEntity:100");
        root.setAttribute("xmlns:qdt", "urn:un:unece:uncefact:data:standard:QualifiedDataType:100");

        // Invoice Header (ExchangedDocumentContext)
        let exchangedDocumentContext = xmlDoc.createElement("rsm:ExchangedDocumentContext");
        let guideline = xmlDoc.createElement("ram:GuidelineSpecifiedDocumentContextParameter");
        let guidelineID = xmlDoc.createElement("ram:ID");
        guidelineID.textContent = "urn:cen.eu:en16931:2017";
        guideline.appendChild(guidelineID);
        exchangedDocumentContext.appendChild(guideline);
        root.appendChild(exchangedDocumentContext);

        // Exchanged Document (Invoice Number and Date)
        let exchangedDocument = xmlDoc.createElement("rsm:ExchangedDocument");
        let invoiceNr = xmlDoc.createElement("ram:ID");
        invoiceNr.textContent = order.invoiceNr;
        exchangedDocument.appendChild(invoiceNr);
        let typeCode = xmlDoc.createElement("ram:TypeCode");
        typeCode.textContent = "380";
        exchangedDocument.appendChild(typeCode);
        let issueDateTime = xmlDoc.createElement("ram:IssueDateTime");
        let dateTimeString = xmlDoc.createElementNS("urn:un:unece:uncefact:data:standard:UnqualifiedDataType:100", "udt:DateTimeString");
        dateTimeString.setAttribute("format", "YYYY-MM-DD");
        dateTimeString.textContent = order.documentDate;
        issueDateTime.appendChild(dateTimeString);
        exchangedDocument.appendChild(issueDateTime);
        root.appendChild(exchangedDocument);

        // Seller Information
        let tradeTransaction = xmlDoc.createElement("rsm:SupplyChainTradeTransaction");
        let tradeAgreement = xmlDoc.createElement("ram:ApplicableHeaderTradeAgreement");
        let sellerTradeParty = xmlDoc.createElement("ram:SellerTradeParty");
        let sellerName = xmlDoc.createElement("ram:Name");
        sellerName.textContent = props.company.name;
        sellerTradeParty.appendChild(sellerName);

        let sellerAddress = xmlDoc.createElement("ram:PostalTradeAddress");
        let sellerPostcode = xmlDoc.createElement("ram:PostcodeCode");
        sellerPostcode.textContent = props.company.postalCode;
        sellerAddress.appendChild(sellerPostcode);
        let sellerLine = xmlDoc.createElement("ram:LineOne");
        sellerLine.textContent = props.company.streetName + " " + props.company.streetNr;
        sellerAddress.appendChild(sellerLine);
        let sellerCity = xmlDoc.createElement("ram:CityName");
        sellerCity.textContent = props.company.city;
        sellerAddress.appendChild(sellerCity);
        let sellerCountry = xmlDoc.createElement("ram:CountryID");
        switch (props.company.country) {
            case 'Deutschland':
                sellerCountry.textContent = 'DE';
                break;
            case 'USA':
                sellerCountry.textContent = 'US';
                break;
            case 'Frankreich':
                sellerCountry.textContent = 'FR';
                break;
            case 'England':
                sellerCountry.textContent = 'GB'; // England is part of the UK, so we use 'GB' for Great Britain
                break;
            case 'Niederlande':
                sellerCountry.textContent = 'NL';
                break;
            case 'China':
                sellerCountry.textContent = 'CN';
                break;
            case 'Österreich':
                sellerCountry.textContent = 'AT';
                break;
            case 'italien':
                sellerCountry.textContent = 'IT';
                break;
            case 'Polen':
                sellerCountry.textContent = 'PL';
                break;
            case 'Schweiz':
                sellerCountry.textContent = 'CH';
                break;
            case 'Belgien':
                sellerCountry.textContent = 'BE';
                break;
            case 'Spanien':
                sellerCountry.textContent = 'ES';
                break;
            case 'Tschechien':
                sellerCountry.textContent = 'CZ';
                break;
            case 'Schweden':
                sellerCountry.textContent = 'SE';
                break;
            case 'Türkei':
                sellerCountry.textContent = 'TR';
                break;
            case 'Ungarn':
                sellerCountry.textContent = 'HU';
                break;
            case '---':
                sellerCountry.textContent = ''; // No country selected or placeholder
                break;
            default:
                sellerCountry.textContent = ''; // Handle any other undefined values
        }
        sellerAddress.appendChild(sellerCountry);
        sellerTradeParty.appendChild(sellerAddress);

        let sellerTax = xmlDoc.createElement("ram:SpecifiedTaxRegistration");
        let sellerTaxID = xmlDoc.createElement("ram:ID");
        sellerTaxID.setAttribute("schemeID", "VAT");
        sellerTaxID.textContent = props.company.taxId;
        sellerTax.appendChild(sellerTaxID);
        sellerTradeParty.appendChild(sellerTax);
        tradeAgreement.appendChild(sellerTradeParty);

        // Buyer Information
        let buyerTradeParty = xmlDoc.createElement("ram:BuyerTradeParty");
        let buyerName = xmlDoc.createElement("ram:Name");
        buyerName.textContent = order.assignedCustomer.name;
        buyerTradeParty.appendChild(buyerName);

        let buyerAddress = xmlDoc.createElement("ram:PostalTradeAddress");
        let buyerPostcode = xmlDoc.createElement("ram:PostcodeCode");
        buyerPostcode.textContent = order.assignedCustomer.postalCode;
        buyerAddress.appendChild(buyerPostcode);
        let buyerLine = xmlDoc.createElement("ram:LineOne");
        buyerLine.textContent = order.assignedCustomer.streetName + " " + order.assignedCustomer.streetNr;
        buyerAddress.appendChild(buyerLine);
        let buyerCity = xmlDoc.createElement("ram:CityName");
        buyerCity.textContent = order.assignedCustomer.city;
        buyerAddress.appendChild(buyerCity);
        let buyerCountry = xmlDoc.createElement("ram:CountryID");
        buyerCountry.textContent = HelperFunctions.getCountryCodeByCountry(order.assignedCustomer.country);
        buyerAddress.appendChild(buyerCountry);
        buyerTradeParty.appendChild(buyerAddress);

        // Ab hier noch nicht weitergemacht

        let buyerTax = xmlDoc.createElement("ram:SpecifiedTaxRegistration");
        let buyerTaxID = xmlDoc.createElement("ram:ID");
        buyerTaxID.setAttribute("schemeID", "VAT");
        buyerTaxID.textContent = "DE987654321";
        buyerTax.appendChild(buyerTaxID);
        buyerTradeParty.appendChild(buyerTax);
        tradeAgreement.appendChild(buyerTradeParty);

        // Add trade agreement to the root element
        tradeTransaction.appendChild(tradeAgreement);

        // Add Line Item
        let lineItem = xmlDoc.createElement("ram:IncludedSupplyChainTradeLineItem");

        let tradeProduct = xmlDoc.createElement("ram:SpecifiedTradeProduct");
        let productName = xmlDoc.createElement("ram:Name");
        productName.textContent = "Software Development Service";
        tradeProduct.appendChild(productName);
        lineItem.appendChild(tradeProduct);

        let tradeAgreementLine = xmlDoc.createElement("ram:SpecifiedLineTradeAgreement");
        let price = xmlDoc.createElement("ram:GrossPriceProductTradePrice");
        let chargeAmount = xmlDoc.createElement("ram:ChargeAmount");
        chargeAmount.textContent = "1000.00";
        price.appendChild(chargeAmount);
        tradeAgreementLine.appendChild(price);
        lineItem.appendChild(tradeAgreementLine);

        let tradeDelivery = xmlDoc.createElement("ram:SpecifiedLineTradeDelivery");
        let quantity = xmlDoc.createElement("ram:BilledQuantity");
        quantity.setAttribute("unitCode", "HUR");
        quantity.textContent = "1";
        tradeDelivery.appendChild(quantity);
        lineItem.appendChild(tradeDelivery);

        let tradeSettlement = xmlDoc.createElement("ram:SpecifiedLineTradeSettlement");
        let tax = xmlDoc.createElement("ram:ApplicableTradeTax");
        let taxTypeCode = xmlDoc.createElement("ram:TypeCode");
        taxTypeCode.textContent = "VAT";
        tax.appendChild(taxTypeCode);
        let taxCategory = xmlDoc.createElement("ram:CategoryCode");
        taxCategory.textContent = "S";
        tax.appendChild(taxCategory);
        let taxRate = xmlDoc.createElement("ram:RateApplicablePercent");
        taxRate.textContent = "19.00";
        tax.appendChild(taxRate);
        tradeSettlement.appendChild(tax);

        let lineTotal = xmlDoc.createElement("ram:SpecifiedTradeSettlementLineMonetarySummation");
        let lineTotalAmount = xmlDoc.createElement("ram:LineTotalAmount");
        lineTotalAmount.textContent = "1000.00";
        lineTotal.appendChild(lineTotalAmount);
        tradeSettlement.appendChild(lineTotal);
        lineItem.appendChild(tradeSettlement);

        // Add line item to the root element
        tradeTransaction.appendChild(lineItem);

        // Add the trade transaction to the root element
        root.appendChild(tradeTransaction);

        // Serialize the XML document to a string
        let serializer = new XMLSerializer();
        let xmlString = serializer.serializeToString(xmlDoc);

        // Create a Blob object for downloading the file
        let blob = new Blob([xmlString], { type: "application/xml" });
        let link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "XRechnung_Invoice.xml";
        link.click();
    }

    const togglePositionDisplay = () => {
        setPositionDisplay(!positionDisplay);
    }

    const overtakeInformation = () => {
        const updatedOrder = { ...order };
        switch (documentKind) {
            case "DELIVERY_BILL":
                updatedOrder.deliveryBillDate = lastGeneratedOrder.documentDate.substring(0,10);
                updatedOrder.deliveryBillNr = lastGeneratedOrder.deliveryBillNr;
                if(props.order.description) {
                    updatedOrder.description = props.order.description;
                }else if(props.company.defaultInvoiceDescription) {
                    updatedOrder.description = props.company.defaultInvoiceDescription
                }
                setDocumentKind("INVOICE");
                props.showMessage(1,"Die Dokumentenart wurde auf Rechnung gesetzt und die entsprechenden Werte wurden vorbefüllt");
                break;
        }
        setOrder(updatedOrder);
    }

    const maxAllInventoryCounts = () => {
        let updatedOrder = { ...order };
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            updatedOrder.documentPositions[i].active = true;
            updatedOrder.documentPositions[i].inventoryCount = updatedOrder.documentPositions[i].originalInventoryCount;
        }
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedOrder.documentPositions)
        });
    }

    const toggleEInvoiceOnClickHandler = () => {
        setEInvoice(!eInvoice);
    }

    const addressOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        updatedOrder.address = e.target.value;
        if(e.target.value.kind === "INDIVIDUAL") {
            updatedOrder.address = {
                streetName:"",
                streetNr:"",
                supplement:"",
                postalCode:"",
                city:"",
                country:"",
                kind:"INDIVIDUAL"
            };
        }
        setOrder(updatedOrder);
    }

    const streetNameOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.streetName = e.target.value;
        setOrder(updatedOrder);
    }

    const streetNrOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.streetNr = e.target.value;
        setOrder(updatedOrder);
    }

    const supplementOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.supplement = e.target.value;
        setOrder(updatedOrder);
    }

    const postalCodeOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.postalCode = e.target.value;
        setOrder(updatedOrder);
    }

    const cityOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.city = e.target.value;
        setOrder(updatedOrder);
    }

    const countryOnChangeHandler = (e) => {
        let updatedOrder = { ...order };
        if(!updatedOrder.address) {
            updatedOrder.address = {
                kind:"INDIVIDUAL"
            };
        }
        updatedOrder.address.country = e.target.value;
        setOrder(updatedOrder);
    }

    const onClose = () => {
        props.close();
        setDocumentKind(null);
    }

    const showGenerateDocumentWarningDialog = () => {
        setGenerateDocumentWarningDialog(true);
    }

    const closeGenerateDocumentWarningDialog = () => {
        setGenerateDocumentWarningDialog(false);
    }

    const showOvertakeInformationDialog = () => {
        setOvertakeInformationDialog(true);
    }

    const closeOvertakeInformationDialog = () => {
        setOvertakeInformationDialog(false);
    }

    let positionDisplayText;

    if(positionDisplay) {
        positionDisplayText = "Auflistungsansicht";
    }else{
        positionDisplayText = "Detailanischt";
    }

    return (
            <Dialog scroll='body' maxWidth={"xl"} open={props.open} onClose={onClose} className='generate-document-dialog'>

                <Tooltip id="generate-document-dialog-tooltip"/>

                <YesCancelDialog
                    open={overtakeInformationDialog}
                    close={closeOvertakeInformationDialog}
                    header='Informationen übernehmen'
                    text={documentKind === "DELIVERY_BILL" ? 'Wollen Sie zur Rechnungsgenerierungsmaske wechseln und die entsprechenden Werte direkt übernehmen?' : ""}
                    onClick={overtakeInformation}
                />

                <YesCancelDialog
                    open={generateDocumentWarningDialog}
                    close={closeGenerateDocumentWarningDialog}
                    header='Dokument generieren'
                    text='Sie sind dabei, ein Dokument zu generieren, welches für mindestens eine Position die Anzahl der gesamten Stückzahlen übersteigt. Wollen Sie trotzdem fortfahren?'
                    onClick={() => executeGenerateDocument(order,documentKind)}
                />

                <div className='surrounder'>
                    <Paper elevation={3} className="quick-actions">
                        <h2>Aktionsübersicht</h2>
                        <ToolButton main disabled={!documentKind} className='button' onClick={() => generateDocumentOnClickHandler(order,documentKind)}>Generieren</ToolButton>
                    </Paper>
                    <CloseIcon onClick={props.close} className='icon close-icon'/>
                    <DialogTitle>Dokument generieren</DialogTitle>
                    <DialogContent>
                        <div className="document-kind-holder">
                            <TextField size="small" value={documentKind} onChange={documentKindOnChangeHandler} select className='field status-field' label='Dokumentenart'>
                                {props.kind === 0 ? <MenuItem key={0} value="OFFER">Angebot</MenuItem> : null}
                                {props.kind === 0 ? <MenuItem key={1} value="ORDER_CONFIRMATION">Auftragsbestätigung</MenuItem> : null}
                                {props.kind === 0 ? <MenuItem key={2} value="DELIVERY_BILL">Lieferschein</MenuItem> : null}
                                {props.kind === 0 ? <MenuItem key={3} value="INVOICE">Rechnung</MenuItem> : null}
                                {props.kind === 0 ? <MenuItem key={4} value="CANCELLATION_INVOICE">Stornorechnung</MenuItem> : null}
                                {props.kind === 1 ? <MenuItem key={5} value="REQUEST">Anfrage</MenuItem> : null}
                                {props.kind === 1 ? <MenuItem key={6} value="ORDER">Bestellung</MenuItem> : null}
                            </TextField>
                            {false && documentKind && (documentKind === "INVOICE" || documentKind === "CANCELLATION_INVOICE") ? <FormGroup>
                                <FormControlLabel disabled={props.locked} className="field e-invoice-checkbox-field" control={<Checkbox className="checkbox" checked={eInvoice} onChange={toggleEInvoiceOnClickHandler} />} label="zusätzlich eRechnung generieren" />
                            </FormGroup> : null}
                        </div>
                        {documentKind ? <div>
                            <div className="meta-information-holder">
                                <TextField size="small" value={order ? order.description : ''} multiline rows={5} label='Beschreibung (maximal 2000 Zeichen)' inputProps={{maxLength:2000}} InputLabelProps={{shrink: true}} variant="outlined" placeholder="Erscheint am Anfang des Dokuments" className='textarea' onChange={descriptionOnChangeHandler}/>
                                <TextField size="small" value={order ? order.endDescription : ''} multiline rows={5} label='Ende Beschreibung (maximal 2000 Zeichen)' inputProps={{maxLength:2000}} InputLabelProps={{shrink: true}} variant="outlined" placeholder="Erscheint am Ende des Dokuments" className='textarea' onChange={endDescriptionOnChangeHandler}/>
                                <div>
                                    <TextField size="small" onChange={addressOnChangeHandler} InputLabelProps={{shrink: true}} value={order && order.address ? order.address : ''} label='Adresse' className='field' select>
                                        <MenuItem value={{kind:"INDIVIDUAL"}}>Individuell</MenuItem>
                                        {order && order.assignedCustomer && order.assignedCustomer.addresses ? order.assignedCustomer.addresses.map(address => (
                                            <MenuItem value={address}>
                                                {address.kind === "STANDARD" ? "Standard (" + address.streetName + ")" : null}
                                                {address.kind === "INVOICE" ? "Rechnung (" + address.streetName + ")" : null}
                                                {address.kind === "DELIVERY_BILL" ? "Lieferschein (" + address.streetName + ")" : null}
                                                {address.kind === "OTHER" ? "Andere (" + address.streetName + ")" : null}
                                                {address.kind === "ORDER_CONFIRMATION" ? "Auftragsbestätigung (" + address.streetName + ")" : null}
                                                {address.kind === "ORDER" ? "Bestellung (" + address.streetName + ")" : null}
                                                {address.kind === "OFFER" ? "Angebot (" + address.streetName + ")" : null}
                                                {address.kind === "REQUEST" ? "Anfrage (" + address.streetName + ")" : null}
                                                {address.kind === "FACTORY_CERTIFICATE" ? "Werkszeugnis (" + address.streetName + ")" : null}
                                            </MenuItem>
                                        )) : null}
                                        {order && order.assignedSupplier && order.assignedSupplier.addresses ? order.assignedSupplier.addresses.map(address => (
                                            <MenuItem value={address}>
                                                {address.kind === "STANDARD" ? "Standard (" + address.streetName + ")" : null}
                                                {address.kind === "INVOICE" ? "Rechnung (" + address.streetName + ")" : null}
                                                {address.kind === "DELIVERY_BILL" ? "Lieferschein (" + address.streetName + ")" : null}
                                                {address.kind === "OTHER" ? "Andere (" + address.streetName + ")" : null}
                                                {address.kind === "ORDER_CONFIRMATION" ? "Auftragsbestätigung (" + address.streetName + ")" : null}
                                                {address.kind === "ORDER" ? "Bestellung (" + address.streetName + ")" : null}
                                                {address.kind === "OFFER" ? "Angebot (" + address.streetName + ")" : null}
                                                {address.kind === "REQUEST" ? "Anfrage (" + address.streetName + ")" : null}
                                                {address.kind === "FACTORY_CERTIFICATE" ? "Werkszeugnis (" + address.streetName + ")" : null}
                                            </MenuItem>
                                        )) : null}
                                    </TextField>
                                    {order.address ? <div>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.streetName} label='Straßenname' InputLabelProps={{shrink: true}} className='field' onChange={streetNameOnChangeHandler}/>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.streetNr} label='Hausnummer' InputLabelProps={{shrink: true}} className='field' onChange={streetNrOnChangeHandler}/>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.supplement} label='Zusatz' InputLabelProps={{shrink: true}} className='field' onChange={supplementOnChangeHandler}/>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.postalCode} label='Postleitzahl' InputLabelProps={{shrink: true}} className='field' onChange={postalCodeOnChangeHandler}/>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.city} label='Stadt' InputLabelProps={{shrink: true}} className='field' onChange={cityOnChangeHandler}/>
                                        <TextField disabled={order.address.kind !== "INDIVIDUAL"} size="small" value={order.address.country} label='Land' InputLabelProps={{shrink: true}} className='field' select onChange={countryOnChangeHandler}>
                                            {props.countries}
                                        </TextField>
                                    </div> : null}
                                </div>
                                <TextField size="small" onChange={documentNameOnChangeHandler} data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content='wird automatisch generiert wenn leer gelassen' inputProps={{maxLength:45}} InputLabelProps={{shrink: true}} value={order && order.documentName ? order.documentName : ''} label='Dokumentenname' className='field'/>
                                <TextField size="small" type='date' value={order ? order.documentDate : ''} label='Dokumentendatum' InputLabelProps={{shrink: true}} className='field' onChange={documentDateOnChangeHandler}/>
                                {documentKind === "INVOICE" || documentKind === "CANCELLATION_INVOICE" ? <div>
                                    <TextField size="small" onChange={invoiceNrOnChangeHandler} value={order && order.invoiceNr ? order.invoiceNr : ''} label='Rechnungsnummer' inputProps={{maxLength:9}} InputLabelProps={{shrink: true}} type='number' onWheel={(e) => e.target.blur()} data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content={documentKind === 'INVOICE' || documentKind === 'CANCELLATION_INVOICE' ? 'wird automatisch generiert wenn leer gelassen' : null} className='field'/>
                                    {documentKind === "CANCELLATION_INVOICE" ?

                                        <Autocomplete
                                            size="small"
                                            value={order && order.cancellationInvoiceNr ? order.cancellationInvoiceNr : ''}
                                            onChange={(e,newValue) => cancellationInvoiceNrOnChangeHandler(newValue)}
                                            disableClearable
                                            className='field'
                                            options={props.order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.documentNr && fileStorageLocation.kind === "INVOICE").map((option) => option)}
                                            renderOption={(props, option) => <li {...props}>{option.documentNr}</li>}
                                            getOptionLabel={(option) => {
                                                return option;
                                            }}
                                            freeSolo
                                            renderInput={(params) => <TextField size="small" {...params} onChange={(e) => cancellationInvoiceNrOnChangeHandler({documentNr:e.target.value})} inputProps={{ ...params.inputProps, maxLength: 9 }} InputLabelProps={{shrink: true}} label='Rechnungsnummer der stornierten Rechnung' type='number' onWheel={(e) => e.target.blur()} data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content={documentKind === 'CANCELLATION_INVOICE' ? 'Die Rechnungsnummer auf die sich die Stornorechnung bezieht' : null}/>}
                                        /> : null}
                                    {documentKind === "CANCELLATION_INVOICE" ? <TextField size="small" onChange={cancellationInvoiceDateOnChangeHandler} value={order && order.cancellationInvoiceDate ? order.cancellationInvoiceDate : ''} label='Rechnungsdatum der stornierten Rechnung' type='date' data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content={documentKind === 'CANCELLATION_INVOICE' ? 'Das Rechnungsdatum auf die sich die Stornorechnung bezieht' : null} InputLabelProps={{shrink: true}} className='field'/> : null}
                                    {documentKind === "INVOICE" ? <TextField size="small" inputProps={{maxLength:3}} InputLabelProps={{shrink: true}} value={order ? order.paymentTarget : ''} onChange={paymentTargetOnChangeHandler} type="number" className='field' label='Zahlungsziel in Tagen'/> : null}
                                </div> : null}
                                {documentKind === "OFFER" || documentKind === "ORDER_CONFIRMATION" ? <TextField size="small" onChange={offerNrOnChangeHandler} inputProps={{maxLength:9}} InputLabelProps={{shrink: true}} value={order && order.offerNr ? order.offerNr : ''} label='Angebotsnummer' type='number' onWheel={(e) => e.target.blur()} data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content={documentKind === 'OFFER' ? 'wird automatisch generiert wenn leer gelassen' : null} className='field'/> : null}
                                {documentKind === "DELIVERY_BILL" || documentKind === "INVOICE" ? <div>
                                    <TextField size="small" onChange={deliveryBillNrOnChangeHandler} inputProps={{maxLength:9}} InputLabelProps={{shrink: true}} value={order && order.deliveryBillNr ? order.deliveryBillNr : ''} label='Lieferscheinnummer' type='number' onWheel={(e) => e.target.blur()} data-tooltip-id="generate-document-dialog-tooltip" data-tooltip-place="top" data-tooltip-content={documentKind === 'DELIVERY_BILL' ? 'wird automatisch generiert wenn leer gelassen' : null} className='field'/>
                                    <div className="element-group">
                                        {documentKind === "DELIVERY_BILL" ? <TextField size="small" type='date' value={order ? order.deliveryDate : ''} label='Lieferdatum' InputLabelProps={{shrink: true}} className='field' onChange={deliveryDateOnChangeHandler}/> : null}
                                        {documentKind === "INVOICE" ? <TextField size="small" type='date' value={order ? order.deliveryBillDate : ''} label='Lieferscheindatum' InputLabelProps={{shrink: true}} className='field' onChange={deliveryBillDateOnChangeHandler}/> : null}
                                        {documentKind === "DELIVERY_BILL" ? <FormGroup>
                                            <FormControlLabel disabled={props.locked} className="field checkbox-field" control={<Checkbox className="checkbox" checked={order ? order.deliveryDateAnticipated : null} onChange={deliveryDateAnticipatedOnChangeHandler} />} label="unter Vorbehalt" />
                                        </FormGroup> : null}
                                    </div>
                                </div> : null}
                                {documentKind === "OFFER" || documentKind === "INVOICE" || documentKind === "CANCELLATION_INVOICE" || documentKind === "ORDER_CONFIRMATION" ? <FormGroup>
                                    <FormControlLabel disabled={props.locked} className="field checkbox-field" control={<Checkbox className="checkbox" checked={order ? order.noVat : null} onChange={noVatOnChangeHandler} />} label="Umsatzsteuer frei" />
                                </FormGroup> : null}
                            </div>
                            <div className="position-content-holder">
                                <div className="position-holder">
                                    <FormControlLabel
                                        className='switch-label'
                                        control={
                                            <Switch className='switch position-display-switch' checked={positionDisplay}
                                                    onChange={togglePositionDisplay} color="primary"/>
                                        }
                                        label={positionDisplayText}
                                    />
                                    <VerticalAlignTopIcon className="icon max-icon" onClick={maxAllInventoryCounts}
                                                          data-tooltip-id="generate-document-dialog-tooltip"
                                                          data-tooltip-place="top"
                                                          data-tooltip-content='Setzt alle Positionen auf die ursprüngliche maximal Anzahl'/>
                                    <div className="positions">
                                        {positionDisplay && order && order.documentPositions ? order.documentPositions.map((documentPosition, index) => (
                                            <DocumentPosition
                                                key={documentPosition.id}
                                                documentPosition={documentPosition}
                                                order={order}
                                                generateDocumentPosition={true}
                                                kind={props.kind}
                                                type={documentKind}
                                                index={index}
                                                company={props.company}
                                                moveDocumentPositionUp={moveDocumentPositionUp}
                                                moveDocumentPositionDown={moveDocumentPositionDown}
                                                inventoryCountOnChange={inventoryCountOnChangeHandler}
                                                unitOnChange={unitOnChangeHandler}
                                                piecePriceOnChange={piecePriceOnChange}
                                                positionVatOnChange={positionVatOnChange}
                                                descriptionOnChangeHandler={positionDescriptionOnChangeHandler}
                                                hintOnChangeHandler={positionHintOnChangeHandler}
                                                removeOrderPosition={removeOrderPosition}
                                                offerPositionOnChangeHandler={offerPositionOnChangeHandler}
                                                guidePriceOnChangeHandler={guidePriceOnChangeHandler}
                                                toggleActive={toggleActive}
                                                noteOnChangeHandler={positionNoteOnChangeHandler}
                                                deliveryDateOnChangeHandler={positionDeliveryDateOnChangeHandler}
                                            />
                                        )) : null}
                                        {!positionDisplay && order && order.documentPositions ?
                                            <div>
                                                {positionIndex !== null && order.documentPositions[positionIndex] ?
                                                    <div className="position-display-holder">
                                                        <ul className="position-navigation-holder">
                                                            <li className="navigation-item animate"
                                                                onClick={firstPositionOnClickHandler}>
                                                                <FirstPageIcon className="position-navigation-icon"/>
                                                            </li>
                                                            <li className="navigation-item animate"
                                                                onClick={prevPositionOnClickHandler}>
                                                                <KeyboardArrowLeftIcon
                                                                    className="position-navigation-icon"/>
                                                            </li>
                                                            <li className="navigation-item animate"
                                                                onClick={nextPositionOnClickHandler}>
                                                                <KeyboardArrowRightIcon
                                                                    className="position-navigation-icon"/>
                                                            </li>
                                                            <li className="navigation-item animate"
                                                                onClick={lastPositionOnClickHandler}>
                                                                <LastPageIcon className="position-navigation-icon"/>
                                                            </li>
                                                        </ul>
                                                        <DocumentPosition
                                                            key={positionIndex}
                                                            index={positionIndex}
                                                            generateDocumentPosition={true}
                                                            toggleActive={toggleActive}
                                                            kind={props.kind}
                                                            type={documentKind}
                                                            user={props.user}
                                                            documentPosition={order.documentPositions[positionIndex]}
                                                            order={order}
                                                            locked={props.locked}
                                                            moveDocumentPositionUp={(e) => moveDocumentPositionUp(e, positionIndex)}
                                                            moveDocumentPositionDown={moveDocumentPositionDown}
                                                            offerPositionOnChangeHandler={offerPositionOnChangeHandler}
                                                            inventoryCountOnChange={inventoryCountOnChangeHandler}
                                                            unitOnChange={unitOnChangeHandler}
                                                            piecePriceOnChange={piecePriceOnChange}
                                                            positionVatOnChange={positionVatOnChange}
                                                            removeOrderPosition={removeOrderPosition}
                                                            descriptionOnChangeHandler={positionDescriptionOnChangeHandler}
                                                            articles={props.articles}
                                                            company={props.company}
                                                            noteOnChangeHandler={positionNoteOnChangeHandler}
                                                            deliveryDateOnChangeHandler={positionDeliveryDateOnChangeHandler}
                                                        />
                                                    </div> : <p className="position-info">Die Bestellung hat keine
                                                        Positionen</p>}
                                            </div> : null}
                                    </div>
                                </div>
                                <div className='button-holder'>
                                    <ToolButton main disabled={!documentKind} className='button'
                                                onClick={() => generateDocumentOnClickHandler(order, documentKind)}>Generieren</ToolButton>
                                </div>
                            </div>
                        </div> : null}
                    </DialogContent>
                </div>
            </Dialog>
    )
}

export default GenerateDocumentDialog;