import React, { useEffect, useState } from "react";
import './DocumentPosition.css';

// Components
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import triangleUp from "./../../images/triangle-up.svg";
import triangleDown from "./../../images/triangle-down.svg";
import Batch from "../../components/Tool/ModuleOverview/CommonComponents/Actions/OrderManagement/Orders/Batch/Batch";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Paper from "@mui/material/Paper";
import { Tooltip } from 'react-tooltip'
import HelperFunctions from "../HelperFunctions";

const DocumentPosition = (props) => {

    const filter = createFilterOptions();

    let style;
    let disabledStyle;

    if (props.locked) {
        disabledStyle = {
            color: "gray"
        };
    }

    if (!props.generateDocumentPosition && props.documentPosition.state) {
        switch (props.documentPosition.state) {
            case "OFFER_CREATION":
                style = {
                    background: "lightgray"
                };
                break;
            case "NOT_YET_APPROVED":
                style = {
                    background: "lightcoral"
                };
                break;
            case "WORK_IN_PROGRESS":
                style = {
                    background: "orange"
                };
                break;
            case "COMPLETED":
                style = {
                    background: "lightgreen"
                };
                break;
            case "OFFER_REQUESTED":
                style = {
                    background: "lightgray"
                };
                break;
            case "REQUESTED":
                style = {
                    background: "gray"
                };
                break;
            case "CONFIRMED":
                style = {
                    background: "lightcoral"
                };
                break;
            case "REJECTED":
                style = {
                    background: "black"
                };
                break;
            case "GOODS_RECEIVED":
                style = {
                    background: "lightgreen"
                };
                break;
            default:
                break;
        }
    }

    return (
        <Paper key={props.key} elevation={!props.generateDocumentPosition || props.documentPosition.active ? 3 : 1} style={!props.generateDocumentPosition || props.documentPosition.active ? null : { background: "#E6E6E6" }} className='document-position'>
            <Tooltip id="document-position-tooltip"/>
            {!props.generateDocumentPosition ? <span style={style} className="position-state" /> : null}
            <div className="content-holder">
                <div className="top-holder">
                    {!props.hideCloseButton ? props.generateDocumentPosition ? <Checkbox className="checkbox active-checkbox" checked={props.documentPosition.active} onChange={() => props.toggleActive(props.index)} /> : <CloseIcon style={disabledStyle} onClick={!props.locked ? () => props.removeOrderPosition(props.index) : null} className='icon close-icon' /> : null}
                    {props.kind === 0 && props.company && props.company.companySettings && props.company.companySettings.executionManagement ?
                        <TextField disabled={props.locked || props.generateDocumentPosition} size="small" value={props.documentPosition.state ? props.documentPosition.state : 'NO_STATE'} onChange={(e) => props.documentPositionStateOnChangeHandler(e, props.index)} select className='state-select' label='Status'>
                            <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                            <MenuItem key={1} value="OFFER_CREATION">Angebotserstellung</MenuItem>
                            <MenuItem key={2} value="NOT_YET_APPROVED">Noch nicht freigegeben</MenuItem>
                            <MenuItem key={3} value="WORK_IN_PROGRESS">In Bearbeitung</MenuItem>
                            <MenuItem key={4} value="COMPLETED">Abgeschlossen</MenuItem>
                        </TextField> : null}
                    {props.kind === 1 && props.company && props.company.companySettings && props.company.companySettings.executionManagement ?
                        <TextField disabled={props.locked || props.generateDocumentPosition} size="small" value={props.documentPosition.state ? props.documentPosition.state : 'NO_STATE'} onChange={(e) => props.documentPositionStateOnChangeHandler(e, props.index)} select className='state-select' label='Status'>
                            <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                            <MenuItem key={1} value="OFFER_REQUESTED">Angebot angefragt</MenuItem>
                            <MenuItem key={2} value="OFFER_RECEIVED">Angebot erhalten</MenuItem>
                            <MenuItem key={3} value="REQUESTED">Bestellung angefragt</MenuItem>
                            <MenuItem key={4} value="CONFIRMED">Bestätigt</MenuItem>
                            <MenuItem key={5} value="REJECTED">Abgelehnt</MenuItem>
                            <MenuItem key={6} value="GOODS_RECEIVED">Ware erhalten</MenuItem>
                        </TextField> : null}
                    {props.type === "ORDER" || props.type === "REQUEST" || props.type === "OFFER" || props.type === "INVOICE" || props.type === "CANCELLATION_INVOICE" || props.type === "DELIVERY_BILL" || props.type === "ORDER_CONFIRMATION" ?
                        <div className='position-count-holder'>
                            <img onClick={!props.locked ? () => props.moveDocumentPositionUp(props.index) : null} className="clickable triangle-icon up" src={triangleUp} alt="Pfeil nach oben" />
                            {props.generateDocumentPosition ? <p className="position-p">{props.documentPosition.active ? props.documentPosition.displayedPosition : "-"}</p> : null}
                            {!props.generateDocumentPosition ? <p className="position-p">{props.documentPosition.position ? props.documentPosition.position : props.index + 1}</p> : null}
                            <img onClick={!props.locked ? () => props.moveDocumentPositionDown(props.index) : null} className="clickable triangle-icon down" src={triangleDown} alt="Pfeil nach unten" />
                        </div> : null}
                    {!props.generateDocumentPosition && !props.documentPosition.batch ? <SettingsSuggestIcon style={disabledStyle} onClick={!props.locked ? () => props.addBatch(props.index) : null} data-tooltip-id="document-position-tooltip" data-tooltip-place="top" data-tooltip-content="Chargeninformationen hinzufügen" className="clickable icon batch-icon" /> : null}
                </div>
                {props.documentPosition && !props.generateDocumentPosition && props.documentPosition.batch && (
                    <Batch
                        locked={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)}
                        index={props.index}
                        batch={props.documentPosition.batch}
                        order={props.order}
                        user={props.user}
                        company={props.company}
                        batchNrOnChangeHandler={props.batchNrOnChangeHandler}
                        batchMaterialOnChangeHandler={props.batchMaterialOnChangeHandler}
                        batchAddFactoryCertificate={props.batchAddFactoryCertificate}
                        batchRemoveFactoryCertificate={props.batchRemoveFactoryCertificate}
                        batchToggleFactoryCertificateADW2={props.batchToggleFactoryCertificateADW2}
                        batchFactoryCertificateFolderStorageOnChangeHandler={props.batchFactoryCertificateFolderStorageOnChangeHandler}
                        batchFactoryCertificateFileOnChangeHandler={props.batchFactoryCertificateFileOnChangeHandler}
                        removeBatch={props.removeBatch}
                        articles={props.articles}
                        showSendMailDialog={props.showSendMailDialog}
                        showOriginalFileDialog={props.showOriginalFileDialog}
                        showEditedFileDialog={props.showEditedFileDialog}
                        showPDFDialog={props.showPDFDialog}
                        showDeleteFileDialog={props.showDeleteFileDialog}
                        showEditPdfDialog={props.showEditPdfDialog}
                        showDeleteOriginalFileDialog={props.showDeleteOriginalFileDialog}
                        showDeleteEditedFileDialog={props.showDeleteEditedFileDialog}
                        // Email
                        addEmailAttachment={props.addEmailAttachment}
                        emailAttachments={props.emailAttachments}
                    />
                )}
                <FormGroup className="form-group">
                    <FormControlLabel
                        disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)}
                        className="field checkbox-field"
                        data-tooltip-id="document-position-tooltip" data-tooltip-place="top" data-tooltip-content={props.kind === 0 ? "Wird auf Angeboten nicht zur Summe dazuaddiert." : null}
                        control={<Checkbox className="checkbox" checked={props.documentPosition ? props.documentPosition.offerPosition : null} onChange={() => props.offerPositionOnChangeHandler(props.index)} />}
                        label="Alternativposition"
                    />
                    {!props.generateDocumentPosition || props.type === "OFFER" ? <FormControlLabel
                        disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)}
                        data-tooltip-id="document-position-tooltip" data-tooltip-place="top" data-tooltip-content={props.kind === 0 ? "Ein Richtpreis erscheint auf Angeboten mit einem 'ca.'" : null}
                        className="field checkbox-field"
                        control={<Checkbox className="checkbox" checked={props.documentPosition ? props.documentPosition.guidePrice : null} onChange={() => props.guidePriceOnChangeHandler(props.index)} />}
                        label="Richtpreis"
                    /> : null}
                </FormGroup>
                <div style={{ width: "100%" }}>
                    <Autocomplete
                        disabled={props.locked || props.generateDocumentPosition}
                        size="small"
                        value={props.documentPosition.article && props.documentPosition.article.name ? props.documentPosition.article.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                props.articleOnChange({
                                    name: newValue,
                                }, props.index);
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                props.articleOnChange({
                                    name: newValue.inputValue
                                }, props.index, true);
                            } else {
                                props.articleOnChange(newValue, props.index);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue.length === 0) {
                                return [];
                            }
                            if (inputValue !== '' && !isExisting) {
                                filtered.unshift({
                                    inputValue,
                                    name: `Erstellen "${inputValue}"`,
                                });
                            }
                            return filtered.slice(0, 10);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="articleNames"
                        className='position-field'
                        options={props.articles}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li key={option && option.name ? option.name : ""} {...props}>{option ? option.name ? HelperFunctions.formatString(option.name, 125) : HelperFunctions.formatString(option.nr, 125) : ""}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{ ...params.inputProps, maxLength: 255 }} label="Artikelname" value={props.documentPosition.article && props.documentPosition.article.name ? props.documentPosition.article.name : ''} InputLabelProps={{shrink: true}}/>
                        )}
                    />
                </div>
                <div style={{ width: "100%", float: "left" }}>
                    <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small" value={props.documentPosition && props.documentPosition.inventoryCount !== null ? props.documentPosition.inventoryCount : ''} onChange={(e) => props.inventoryCountOnChange(e, props.index)} className='position-field small-position-field' label='Anzahl' type='number' onWheel={(e) => e.target.blur()} InputLabelProps={{shrink: true}}/>
                    <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small" value={props.documentPosition && props.documentPosition.unit !== null ? props.documentPosition.unit : ''} onChange={(e) => props.unitOnChange(e, props.index)} className='position-field small-position-field' label='Einheit' InputLabelProps={{shrink: true}} inputProps={{ maxLength: 45 }} select>
                        <MenuItem value="Stück">Stück</MenuItem>
                        <MenuItem value="Satz">Satz</MenuItem>
                        <MenuItem value="Pack">Pack</MenuItem>
                        <MenuItem value="L">L</MenuItem>
                        <MenuItem value="m">m</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="g">g</MenuItem>
                    </TextField>
                    <TextField disabled={props.locked || props.generateDocumentPosition} size="small" value={props.documentPosition && props.documentPosition.inventoryCountInvoice !== null ? props.documentPosition.inventoryCountInvoice : ''} onChange={(e) => props.inventoryCountInvoiceOnChange(e, props.index)} className='position-field small-position-field' label='Bereits auf Rechnungen' type='number' onWheel={(e) => e.target.blur()} InputLabelProps={{shrink: true}}/>
                    <TextField disabled={props.locked || props.generateDocumentPosition} size="small" value={props.documentPosition && props.documentPosition.inventoryCountDeliveryBill !== null ? props.documentPosition.inventoryCountDeliveryBill : ''} onChange={(e) => props.inventoryCountDeliveryBillOnChange(e, props.index)} className='position-field small-position-field' label='Bereits auf Lieferscheinen' type='number' onWheel={(e) => e.target.blur()} InputLabelProps={{shrink: true}}/>
                    {props.generateDocumentPosition ? <p style={props.documentPosition.active ? {color:"black"} : {color:"grey"}} className="original-inventory-count-label">von {props.documentPosition.originalInventoryCount}</p> : null}
                </div>
                {(!props.generateDocumentPosition || props.type === "ORDER" || props.type === "REQUEST" || props.type === "OFFER" || props.type === "INVOICE" || props.type === "CANCELLATION_INVOICE" || props.type === "ORDER_CONFIRMATION") && (
                    <div>
                        <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small" value={props.documentPosition && props.documentPosition.piecePrice !== null ? props.documentPosition.piecePrice : ''} onChange={(e) => props.piecePriceOnChange(e, props.index)} className='position-field small-position-field' label='Stückpreis (netto)' type='number' onWheel={(e) => e.target.blur()} InputLabelProps={{shrink: true}}/>
                        <TextField disabled={props.locked || (props.order && props.order.noVat) || (props.generateDocumentPosition && !props.documentPosition.active)} size="small" value={props.documentPosition && props.documentPosition.positionVat !== null ? props.documentPosition.positionVat : ''} onChange={(e) => props.positionVatOnChange(e, props.index)} className='position-field small-position-field' label='Umsatzsteuer in Prozent' type='number' onWheel={(e) => e.target.blur()} InputLabelProps={{shrink: true}}/>
                        {!props.generateDocumentPosition && props.documentPosition.article && props.documentPosition.article.id && props.order && props.order.assignedCustomer && props.order.assignedCustomer.id && (props.type === "ORDER" || props.type === "OFFER" || props.type === "INVOICE" || props.type === "ORDER_CONFIRMATION") ? <AutoFixHighIcon onClick={() => props.loadLastPrice(props.index)} disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} data-tooltip-id="document-position-tooltip" data-tooltip-place="top" data-tooltip-content="Letzten Preis für diesen Kunden mit dieser Spezifikation (sofern gesetzt) laden" className='icon last-price-icon' /> : null}
                    </div>
                )}
                {(!props.generateDocumentPosition || props.type === "ORDER" || props.type === "REQUEST" || props.type === "OFFER" || props.type === "INVOICE" || props.type === "CANCELLATION_INVOICE" || props.type === "DELIVERY_BILL" || props.type === "ORDER_CONFIRMATION") && (
                    <div>
                        {props.company && props.company.companySettings && (
                            <div className="field-holder">
                                {props.order && props.order.deliveryDate ? <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small"
                                           value={props.documentPosition && props.documentPosition.deliveryDate !== null ? props.documentPosition.deliveryDate : ''}
                                           InputLabelProps={{shrink: true}}
                                           onChange={(e) => props.deliveryDateOnChangeHandler(e,props.index)}
                                           className='small-position-field'
                                           type='date'
                                           label='Abw. Lieferdatum'
                                /> : null}
                                {!props.generateDocumentPosition && props.order && props.order.duration ?
                                    <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small"
                                               InputLabelProps={{shrink: true}}
                                               value={props.documentPosition && props.documentPosition.duration !== null ? props.documentPosition.duration : ''}
                                               onChange={(e) => props.durationOnChangeHandler(e, props.index)}
                                               className='small-position-field'
                                               type='number'
                                               onWheel={(e) => e.target.blur()}
                                               label='Abw. Dauer'
                                    /> : null}
                            </div>
                        )}
                        <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small"
                                   value={props.documentPosition && props.documentPosition.note !== null ? props.documentPosition.note : ''}
                                   InputLabelProps={{shrink: true}}
                                   multiline maxRows={5} label='Bemerkung (maximal 255 Zeichen)' inputProps={{ maxLength: 255 }} variant="outlined"
                                   placeholder={props.kind === 0 ? "Erscheint nicht auf Dokumenten" : null} className='textarea'
                                   onChange={(e) => props.noteOnChangeHandler(e,props.index)} />
                        <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small"
                                   value={props.documentPosition && props.documentPosition.description !== null ? props.documentPosition.description : ''}
                                   InputLabelProps={{shrink: true}}
                                   multiline maxRows={20} label='Beschreibung (maximal 2000 Zeichen)'
                                   inputProps={{ maxLength: 2000 }} variant="outlined"
                                   placeholder={props.kind === 0 ? "Erscheint auf Dokumenten" : null} className='textarea'
                                   onChange={(e) => props.descriptionOnChangeHandler(e,props.index)} />
                        <TextField disabled={props.locked || (props.generateDocumentPosition && !props.documentPosition.active)} size="small"
                                   value={props.documentPosition && props.documentPosition.hint !== null ? props.documentPosition.hint : ''}
                                   InputLabelProps={{shrink: true}}
                                   multiline label='Hinweis (maximal 255 Zeichen)'
                                   inputProps={{ maxLength: 255 }} variant="outlined"
                                   placeholder={props.kind === 0 ? "Erscheint hervorgehoben auf Dokumenten" : null} className='textarea'
                                   onChange={(e) => props.hintOnChangeHandler(e,props.index)} />
                    </div>
                )}
            </div>
        </Paper>
    );
}

export default DocumentPosition;
