import React, {Component} from 'react';
import './ItemDialog.css';

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import AttributeItem from "../../../../../../../../reusable/AttributeItem/AttributeItem";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import FileDialog from "../../../../../../../../reusable/FileDialog/FileDialog";
import api from "../../../../../../../../api";
import YesCancelDialog from "../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";

class ItemDialog extends Component {

    state = {
        item:this.props.item,
        accounts:this.props.accounts,
        showUploadImageDialog:false,
        isSaved:true,
        showUnsavedChangesWarningDialog:false
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.setState({item:this.props.item,accounts:this.props.accounts});
        }
    }

    componentDidMount() {
        let item = this.state.item;
        this.setState({item:item});
    }

    saveItemOnClickHandler = () => {
        let checked = true;
        if(this.state.item.name === '') {
            checked = false;
        }
        if(this.props.type === 0) { //article
            for(let i = 0; i < this.state.item.attributeValues.length; i++) {
                if(this.state.item.attributeValues[i].attribute.type === 0 && (this.state.item.attributeValues[i].booleanValue === null || this.state.item.attributeValues[i].booleanValue === '')) {
                    checked = false;
                }else if(this.state.item.attributeValues[i].attribute.type === 1 && (this.state.item.attributeValues[i].numberValue === null || this.state.item.attributeValues[i].numberValue === '')) {
                    checked = false;
                }else if(this.state.item.attributeValues[i].attribute.type === 2 && (this.state.item.attributeValues[i].stringValue === null || this.state.item.attributeValues[i].stringValue === '')) {
                    checked = false;
                }else if(this.state.item.attributeValues[i].attribute.type === -1) {
                    checked = false;
                }
            }
        }
        if(checked) {
            if(this.props.isNewItem) {
                if(this.props.addNewItem(this.state.item)) {
                    this.props.addItemToShownItems(this.state.item);
                }
            }else{
                this.props.updateItem(this.state.item);
            }
            this.setState({isSaved:true});
            this.close();
        }else{
            this.props.showMessage(2,'Bitte alle Pflichtfelder ausfüllen');
        }
    }

    addAttributeOnClickHandler = () => {
        let item = this.state.item;
        item.attributeValues.push({
            index:this.state.item.attributeValues.length,
            name:'',
            mainAttribute:false,
            booleanValue:null,
            numberValue:null,
            stringValue:null,
            attribute: {
                type:-1
            }
        });
        this.setState({item:item,isSaved:false});
    }

    removeAttributeOnClickHandler = (index) => {
        let item = this.state.item;
        item.attributeValues.splice(index,1);
        for(let i = 0; i < item.attributeValues.length; i++) {
            item.attributeValues[i].index = i;
        }
        this.setState({item: item});
    }

    //Change Handler

    //Common

    idOnChangeHandler = (e) => {
        let item = this.state.item;
        item.id = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    nrOnChangeHandler = (e) => {
        let item = this.state.item;
        item.nr = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    eanOnChangeHandler = (e) => {
        let item = this.state.item;
        item.ean = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    refNrOnChangeHandler = (e) => {
        let item = this.state.item;
        item.refNr = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    pcnOnChangeHandler = (e) => {
        let item = this.state.item;
        item.pcn = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    nameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.name = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    vatOnChangeHandler = (e) => {
        let item = this.state.item;
        item.vat = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    // Customer: Payment Information

    defaultPaymentTargetOnChangeHandler = (e) => {
        if(e.target.value <= 999) {
            let item = this.state.item;
            item.defaultPaymentTarget = e.target.value;
            this.setState({item:item,isSaved:false});
        }
    }

    // Article: Price

        //Sales

    descriptionOnChangeHandler = (e) => {
        let item = this.state.item;
        item.description = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    purchaseNetPriceOnChangeHandler = (e) => {
        let item = this.state.item;
        item.purchaseNetPrice = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    salesNetPriceOnChangeHandler = (e) => {
        let item = this.state.item;
        item.salesNetPrice = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    // Article: Shopsystem

    deleteLogoOnClickHandler = () => {
        let image = this.state.image;
        image.image = null;
        this.setState({image:image,isSaved:false});
    }

    addFileToItem = (multipartFile) => {
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.post("/articles/image/" + this.state.item.id,formData)
            .then(response => {
                let item = this.state.item;
                item.fileStorageLocation = response.data;
                this.setState({item:item});
                this.props.showMessage(0,'Datei erfolgreich angehängt');
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    displayFile = (path,fileStorageLocation) => {
        let pathFileRequest = {
            path:path,
            fileStorageLocation:fileStorageLocation
        }
        this.props.displayFile(pathFileRequest);
    }

    // Article: Storage

    warehouseOnChangeHandler = (e) => {
        let item = this.state.item;
        item.warehouse = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    shelfSeriesOnChangeHandler = (e) => {
        let item = this.state.item;
        item.shelfSeries = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    shelfOnChangeHandler = (e) => {
        let item = this.state.item;
        item.shelf = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    storageSpaceOnChangeHandler = (e) => {
        let item = this.state.item;
        item.storageSpace = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    // Article: Attributes

    countOnChangeHandler = (e) => {
        let item = this.state.item;
        item.count = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    weightOnChangeHandler = (e) => {
        let item = this.state.item;
        item.weight = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    dimensionsOnChangeHandler = (e) => {
        let item = this.state.item;
        item.dimensions = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    mainAttributeOnChangeHandler = (e,index) => {
        let item = this.state.item;
        for(let i = 0; i < item.attributeValues.length; i++) {
            item.attributeValues[i].mainAttribute = false;
        }
        item.attributeValues[index].mainAttribute = e.target.checked;
        this.setState({item:item,isSaved:false});
    }

    booleanValueOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.attributeValues[index].booleanValue = e.target.checked;
        item.attributeValues[index].numberValue = null;
        item.attributeValues[index].stringValue = null;
        this.setState({item:item,isSaved:false});
    }

    numberValueOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.attributeValues[index].booleanValue = null
        item.attributeValues[index].numberValue = e.target.value
        item.attributeValues[index].stringValue = null
        this.setState({item:item,isSaved:false})
    }

    stringValueOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.attributeValues[index].booleanValue = null
        item.attributeValues[index].numberValue = null
        item.attributeValues[index].stringValue = e.target.value
        this.setState({item: item,isSaved:false})
    }

    articleAttributeOnChangeHandler = (e,index) => {
        let item = this.state.item;
        this.props.attributes.forEach(attribute => {
            if(attribute.id === e.target.value) {
                switch (attribute.type) {
                    case 0:
                        item.attributeValues[index].booleanValue = false;
                        break;
                    case 1:
                        item.attributeValues[index].numberValue = '';
                        break;
                    case 2:
                        item.attributeValues[index].stringValue = '';
                        break;
                    default:
                        break;
                }
                item.attributeValues[index].attribute = attribute;
                this.setState({item:item,isSaved:false});
            }
        });
    }

    consumptionAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.consumptionAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    stockAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.stockAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    revenueAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.revenueAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    vatAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.vatAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    receivablesAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.receivablesAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    liabilitiesAccountOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.state.accounts.length; i++) {
            if(e.target.value === this.state.accounts[i].id) {
                item.liabilitiesAccount = this.state.accounts[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    articleGroupOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.props.articleGroups.length; i++) {
            if(e.target.value === this.props.articleGroups[i].id) {
                item.articleGroup = this.props.articleGroups[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    defaultSupplierOnChangeHandler = (e) => {
        let item = this.state.item;
        for(let i = 0; i < this.props.suppliers.length; i++) {
            if(e.target.value === this.props.suppliers[i].id) {
                item.defaultSupplier = this.props.suppliers[i];
                break;
            }
        }
        this.setState({item:item,isSaved:false});
    }

    minimumStockOnChangeHandler = (e) => {
        let item = this.state.item;
        item.minimumStock = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    defaultDurationOnChangeHandler = (e) => {
        let item = this.state.item;
        item.defaultDuration = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    attributeUnitOnChangeHandler = (e) => {
        let item = this.state.item;
        item.unit = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    attributeTypeOnChangeHandler = (e) => {
        let item = this.state.item;
        item.type = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    subjectOnChangeHandler = (e) => {
        let item = this.state.item;
        item.subject = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    messageOnChangeHandler = (e) => {
        let item = this.state.item;
        item.message = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    addAddressOnClickHandler = () => {
        let item = this.state.item;
        if(!item.addresses) {
            item.addresses = [];
        }
        item.addresses.push({
            kind:"STANDARD"
        })
        this.setState({item:item});
    }

    removeAddressOnClickHandler = (index) => {
        let item = this.state.item;
        item.addresses.splice(index,1);
        this.setState({item:item});
    }

    kindOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].kind = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    emailOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].email = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    phoneOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].phone = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    streetNameOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].streetName = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    streetNrOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].streetNr = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    supplementOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].supplement = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    postalCodeOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].postalCode = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    cityOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].city = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    countryOnChangeHandler = (e,index) => {
        let item = this.state.item;
        item.addresses[index].country = e.target.value;
        this.setState({item:item,isSaved:false});
    }

    showUploadImageDialog = () => {
        this.setState({showUploadImageDialog:true});
    }

    closeUploadImageDialog = () => {
        this.setState({showUploadImageDialog:false});
    }

    onClose = () => {
        if(!this.state.isSaved) {
            this.showUnsavedChangesWarningDialog();
        }else{
            this.close();
        }
    }

    close = () => {
        this.setState({isSaved:true});
        this.props.close();
    }

    showUnsavedChangesWarningDialog = () => {
        this.setState({showUnsavedChangesWarningDialog:true});
    }

    closeUnsavedChangesWarningDialog = () => {
        this.setState({showUnsavedChangesWarningDialog:false});
    }

    render() {

        let title = 'unbekannt';
        let idTextField = null;
        let nrTextField = null;
        let eanTextField = null;
        let refNrTextField = null;
        let labelName = null;
        let pharmaFields = null;
        let articleGroupField = null;
        let defaultSupplierField = null;
        let vatTextField = null;
        let accountFields = null;

        //Payment Information
        let paymentDiv = null;

        //Execution
        let executionDiv = null;

        //Sales
        let pricingDiv = null;

        //Storage
        let storageSpaceDiv = null;

        //Attributes
        let attributesDiv = null;
        let unitTextField = null;

        let dataTypes = null;
        let addressTextFields = null;
        let subjectField = null;
        let messageField = null;
        let infoField = null;

        switch (this.props.type) {
            case 0:
                title = this.props.isNewItem ? 'Artikel anlegen' : 'Artikel bearbeiten'
                labelName = 'Artikelname';

                idTextField = <TextField
                    disabled={this.state.item && this.state.item.id}
                    size="small"
                    value={this.state.item.id}
                    onChange={this.idOnChangeHandler}
                    className='field'
                    label='Identifikationsnummer'
                    inputProps={{maxLength:255}}
                />

                nrTextField = <TextField
                    size="small"
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Artikelnummer'
                    inputProps={{maxLength:255}}
                />

                eanTextField = <TextField
                    size="small"
                    value={this.state.item.ean}
                    onChange={this.eanOnChangeHandler}
                    className='field'
                    label='EAN'
                    inputProps={{maxLength:255}}
                />

                refNrTextField = <TextField
                    size="small"
                    value={this.state.item.refNr}
                    onChange={this.refNrOnChangeHandler}
                    className='field'
                    label='Referenznummer (Bestellung)'
                    inputProps={{maxLength:255}}
                />

                pharmaFields = this.props.company && this.props.company.industry && this.props.company.industry === "PHARMA" ? <div>
                    <h3>Pharma</h3>
                    <TextField
                        size="small"
                        value={this.state.item.pcn}
                        onChange={this.pcnOnChangeHandler}
                        className='field'
                        label='Pharmazentralnummer'
                        inputProps={{maxLength:255}}
                    />
                </div> : null;

                articleGroupField = <TextField size="small" value={this.state.item.articleGroup ? this.state.item.articleGroup.id : null} onChange={this.articleGroupOnChangeHandler} select className='field' label='Artikelgruppe'>
                    {this.props.articleGroups ? this.props.articleGroups.map((item,index) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )) : null}
                </TextField>

                defaultSupplierField = <TextField size="small" value={this.state.item.defaultSupplier ? this.state.item.defaultSupplier.id : null} onChange={this.defaultSupplierOnChangeHandler} select className='field' label='Standardlieferant'>
                    {this.props.suppliers ? this.props.suppliers.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )) : null}
                </TextField>

                //Execution
                executionDiv = <div>
                    <h3>Überwachung</h3>
                    <TextField
                        size="small"
                        value={this.state.item && this.state.item.minimumStock ? this.state.item.minimumStock : ''}
                        onChange={this.minimumStockOnChangeHandler}
                        className='field'
                        type='number'
                        label='Mindestbestand'
                        inputProps={{maxLength:9}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item && this.state.item.defaultDuration ? this.state.item.defaultDuration : ''}
                        onChange={this.defaultDurationOnChangeHandler}
                        className='field'
                        type='number'
                        label='Herstellungsdauer'
                        inputProps={{maxLength:3}}
                    />
                </div>

                //Pricing
                pricingDiv = <div>
                    <h3>Preis</h3>
                    <TextField
                        size="small"
                        value={this.state.item.purchaseNetPrice}
                        onChange={this.purchaseNetPriceOnChangeHandler}
                        className='field'
                        type='number'
                        label='Einkaufspreis (netto)'
                        inputProps={{maxLength:9}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.salesNetPrice}
                        onChange={this.salesNetPriceOnChangeHandler}
                        className='field'
                        type='number'
                        label='Verkaufspreis (netto)'
                        inputProps={{maxLength:9}}
                    />
                </div>

                storageSpaceDiv = <div>
                    <h3>Lagerort</h3>
                    <TextField
                        size="small"
                        value={this.state.item.warehouse}
                        onChange={this.warehouseOnChangeHandler}
                        className='field'
                        label='Lagerhalle'
                        inputProps={{maxLength:50}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.shelfSeries}
                        onChange={this.shelfSeriesOnChangeHandler}
                        className='field'
                        label='Regalreihe'
                        inputProps={{maxLength:50}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.shelf}
                        onChange={this.shelfOnChangeHandler}
                        className='field'
                        type='number'
                        label='Regal'
                        inputProps={{maxLength:9}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.storageSpace}
                        onChange={this.storageSpaceOnChangeHandler}
                        className='field'
                        type='number'
                        label='Regalfach'
                        inputProps={{maxLength:9}}
                    />
                </div>
                attributesDiv = <div>
                    <h3>Attribute</h3>
                    <TextField
                        size="small"
                        value={this.state.item.count}
                        onChange={this.countOnChangeHandler}
                        className='field'
                        type='number'
                        label='Verpackungseinheit'
                        inputProps={{maxLength:9}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.weight}
                        onChange={this.weightOnChangeHandler}
                        className='field'
                        type='number'
                        label='Gewicht in g'
                        inputProps={{maxLength:9}}
                    />
                    <TextField
                        size="small"
                        value={this.state.item.dimensions}
                        onChange={this.dimensionsOnChangeHandler}
                        className='field'
                        label='Maße'
                        inputProps={{maxLength:45}}
                    />
                    <div className='attribute-items'>
                        <ToolButton className='add-attribute-button' onClick={this.addAttributeOnClickHandler}>+</ToolButton>
                        {this.state.item.attributeValues ? this.state.item.attributeValues.map((attributeValue,index) => (
                            <AttributeItem
                                key={index}
                                index={index}
                                attributeValue={attributeValue}
                                attributes={this.props.attributes}
                                removeAttributeOnClickHandler={this.removeAttributeOnClickHandler}
                                articleAttributeOnChangeHandler={this.articleAttributeOnChangeHandler}
                                mainAttributeOnChangeHandler={this.mainAttributeOnChangeHandler}
                                booleanValueOnChangeHandler={this.booleanValueOnChangeHandler}
                                numberValueOnChangeHandler={this.numberValueOnChangeHandler}
                                stringValueOnChangeHandler={this.stringValueOnChangeHandler}
                            />
                        )) : null}
                    </div>
                </div>
                break;
            case 1:
                title = this.props.isNewItem ? 'Artikelgruppe anlegen' : 'Artikelgruppe bearbeiten'
                labelName = 'Artikelgruppenname';

                vatTextField = <TextField
                    size="small"
                    value={this.state.item.vat}
                    onChange={this.vatOnChangeHandler}
                    className='field'
                    type='number'
                    label='Umsatzsteuer in Prozent'
                    inputProps={{maxLength:3}}
                />

                //Account Field
                accountFields = <div>
                    <TextField size="small" value={this.state.item.consumptionAccount ? this.state.item.consumptionAccount.id : ''} onChange={this.consumptionAccountOnChangeHandler} select className='field' label='Verbrauchskonto'>
                        {this.state.accounts ? this.state.accounts.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )) : null}
                    </TextField>
                    <TextField size="small" value={this.state.item.stockAccount ? this.state.item.stockAccount.id : ''} onChange={this.stockAccountOnChangeHandler} select className='field' label='Bestandskonto'>
                        {this.state.accounts ? this.state.accounts.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )) : null}
                    </TextField>
                    <TextField size="small" value={this.state.item.revenueAccount ? this.state.item.revenueAccount.id : ''} onChange={this.revenueAccountOnChangeHandler} select className='field' label='Erlöskonto'>
                        {this.state.accounts ? this.state.accounts.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )) : null}
                    </TextField>
                    <TextField size="small" value={this.state.item.vatAccount ? this.state.item.vatAccount.id : ''} onChange={this.vatAccountOnChangeHandler} select className='field' label='Umsatzsteuerkonto'>
                        {this.state.accounts ? this.state.accounts.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        )) : null}
                    </TextField>
                </div>
                break;
            case 2:
                title = this.props.isNewItem ? 'Standort anlegen' : 'Standort bearbeiten'
                labelName = 'Standortname';
                break;
            case 3:
                title = this.props.isNewItem ? 'Kunde anlegen' : 'Kunde bearbeiten'
                labelName = 'Kundennamen';
                nrTextField = <TextField
                    size="small"
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Kundennummer'
                    type="number"
                    inputProps={{maxLength:9}}
                />
                accountFields = <TextField size="small" value={this.state.item.receivablesAccount ? this.state.item.receivablesAccount.id : ''} onChange={this.receivablesAccountOnChangeHandler} select className='field' label='Forderungskonto'>
                    {this.state.accounts ? this.state.accounts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )) : null}
                </TextField>
                paymentDiv = <div>
                    <h3>Zahlungsinformationen</h3>
                    <TextField size="small" value={this.state.item ? this.state.item.defaultPaymentTarget : ''} onChange={this.defaultPaymentTargetOnChangeHandler} type="number" className='field' label='Standard Zahlungsziel in Tagen' inputProps={{maxLength:3}}/>
                </div>
                addressTextFields = <div>
                    <h3>Kontakt</h3>
                    <div className="address-actions-holder">
                        <ToolButton onClick={this.addAddressOnClickHandler} className='button add-address-button'>Adresse
                            hinzufügen</ToolButton>
                    </div>
                    {this.state.item.addresses ? this.state.item.addresses.map((address, index) => (
                        <Paper elevation={3} className="address-holder" key={index}>
                            <DeleteIcon onClick={() => this.removeAddressOnClickHandler(index)} className="icon delete-icon"/>
                            <TextField
                                size="small"
                                value={address.kind}
                                onChange={(e) => this.kindOnChangeHandler(e, index)}
                                className='field'
                                label='Typ'
                                select
                            >
                                <MenuItem value="STANDARD">Standard</MenuItem>
                                {this.props.type === 3 ? <MenuItem value="STANDARD">Standard</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="INVOICE">Rechnung</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="DELIVERY_BILL">Lieferschein</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="ORDER_CONFIRMATION">Auftragsbestätigung</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="OFFER">Angebot</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="FACTORY_CERTIFICATE">Werkszeugnis</MenuItem> : null}
                                {this.props.type === 4 ? <MenuItem value="ORDER">Bestellung</MenuItem> : null}
                                {this.props.type === 4 ? <MenuItem value="REQUEST">Anfrage</MenuItem> : null}
                                {this.props.type === 4 ? <MenuItem value="OTHER">Andere</MenuItem> : null}
                                <MenuItem value="OTHER">Anderes</MenuItem>
                            </TextField>
                            <TextField
                                size="small"
                                value={address.email}
                                onChange={(e) => this.emailOnChangeHandler(e, index)}
                                className='field'
                                label='E-Mail'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.phone}
                                type="tel"
                                onChange={(e) => this.phoneOnChangeHandler(e, index)}
                                className='field'
                                label='Telefonnummer'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.streetName}
                                onChange={(e) => this.streetNameOnChangeHandler(e, index)}
                                className='field'
                                label='Straßename'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.streetNr}
                                onChange={(e) => this.streetNrOnChangeHandler(e, index)}
                                className='field'
                                label='Hausnummer'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.supplement}
                                onChange={(e) => this.supplementOnChangeHandler(e, index)}
                                className='field'
                                label='Adresszusatz'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.postalCode}
                                onChange={(e) => this.postalCodeOnChangeHandler(e, index)}
                                className='field'
                                label='Postleitzahl'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.city}
                                onChange={(e) => this.cityOnChangeHandler(e, index)}
                                className='field'
                                label='Stadt'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField size="small" value={address.country} className='field' select
                                       label='Land' onChange={(e) => this.countryOnChangeHandler(e, index)}
                                       inputProps={{maxLength: 55}}>
                                {this.props.countries}
                            </TextField>
                        </Paper>
                    )) : null}
                </div>
                break;
            case 4:
                title = this.props.isNewItem ? 'Lieferant anlegen' : 'Lieferant bearbeiten'
                labelName = 'Lieferantenname';
                nrTextField = <TextField
                    size="small"
                    value={this.state.item.nr}
                    onChange={this.nrOnChangeHandler}
                    className='field'
                    label='Lieferantennummer'
                    type="number"
                    inputProps={{maxLength: 50}}
                />
                accountFields = <TextField size="small"
                                           value={this.state.item.liabilitiesAccount ? this.state.item.liabilitiesAccount.id : ''}
                                           onChange={this.liabilitiesAccountOnChangeHandler} select className='field'
                                           label='Verbindlichkeitskonto'>
                    {this.state.accounts ? this.state.accounts.map((item) => (
                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )) : null}
                </TextField>
                addressTextFields = <div>
                    <h3>Kontakt</h3>
                    <div className="address-actions-holder">
                        <ToolButton onClick={this.addAddressOnClickHandler} className='button add-address-button'>Adresse
                            hinzufügen</ToolButton>
                    </div>
                    {this.state.item.addresses ? this.state.item.addresses.map((address, index) => (
                        <Paper elevation={3} className="address-holder" key={index}>
                            <DeleteIcon onClick={() => this.removeAddressOnClickHandler(index)} className="icon delete-icon"/>
                            <TextField
                                size="small"
                                value={address.kind}
                                onChange={(e) => this.kindOnChangeHandler(e, index)}
                                className='field'
                                label='Typ'
                                select
                            >
                                <MenuItem value="STANDARD">Standard</MenuItem>
                                {this.props.type === 3 ? <MenuItem value="INVOICE">Rechnung</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="DELIVERY_BILL">Lieferschein</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="ORDER_CONFIRMATION">Auftragsbestätigung</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="OFFER">Angebot</MenuItem> : null}
                                {this.props.type === 3 ? <MenuItem value="FACTORY_CERTIFICATE">Werkszeugnis</MenuItem> : null}
                                {this.props.type === 4 ? <MenuItem value="ORDER">Bestellung</MenuItem> : null}
                                {this.props.type === 4 ? <MenuItem value="REQUEST">Anfrage</MenuItem> : null}
                                <MenuItem value="OTHER">Anderes</MenuItem>
                            </TextField>
                            <TextField
                                size="small"
                                value={address.email}
                                onChange={(e) => this.emailOnChangeHandler(e, index)}
                                className='field'
                                label='E-Mail'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.phone}
                                type="tel"
                                onChange={(e) => this.phoneOnChangeHandler(e, index)}
                                className='field'
                                label='Telefonnummer'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.streetName}
                                onChange={(e) => this.streetNameOnChangeHandler(e, index)}
                                className='field'
                                label='Straßename'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.streetNr}
                                onChange={(e) => this.streetNrOnChangeHandler(e, index)}
                                className='field'
                                label='Hausnummer'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.supplement}
                                onChange={(e) => this.supplementOnChangeHandler(e, index)}
                                className='field'
                                label='Adresszusatz'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.postalCode}
                                onChange={(e) => this.postalCodeOnChangeHandler(e, index)}
                                className='field'
                                label='Postleitzahl'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField
                                size="small"
                                value={address.city}
                                onChange={(e) => this.cityOnChangeHandler(e, index)}
                                className='field'
                                label='Stadt'
                                inputProps={{maxLength: 55}}
                            />
                            <TextField size="small" value={address.country} className='field' select
                                       label='Land' onChange={(e) => this.countryOnChangeHandler(e, index)}
                                       inputProps={{maxLength: 55}}>
                                {this.props.countries}
                            </TextField>
                        </Paper>
                    )) : null}
                </div>

                break;
            case 5:
                title = this.props.isNewItem ? 'Attribut anlegen' : 'Attribut bearbeiten'
                labelName = 'Attributname';
                unitTextField = <TextField size="small" value={this.state.item.unit} onChange={this.attributeUnitOnChangeHandler} select className='field' label='Einheit'>
                    <MenuItem key={0} value={'mg'}>mg</MenuItem>
                    <MenuItem key={1} value={'g'}>g</MenuItem>
                    <MenuItem key={2} value={'kg'}>kg</MenuItem>
                    <MenuItem key={3} value={'mm'}>mm</MenuItem>
                    <MenuItem key={4} value={'cm'}>cm</MenuItem>
                    <MenuItem key={5} value={'m'}>m</MenuItem>
                    <MenuItem key={6} value={'ml'}>ml</MenuItem>
                    <MenuItem key={7} value={'l'}>l</MenuItem>
                    <MenuItem key={8} value={'t'}>t</MenuItem>
                </TextField>
                dataTypes = <TextField size="small" disabled={this.state.item.id} value={this.state.item.type} onChange={this.attributeTypeOnChangeHandler} select className='field' label='Datentyp'>
                    <MenuItem key={0} value={0}>
                        Boolscher Wert
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                        Zahl
                    </MenuItem>
                    <MenuItem key={2} value={2}>
                        Text
                    </MenuItem>
                </TextField>
                break;
            case 6:
                title = this.props.isNewItem ? 'Nachrichtenvorlage anlegen' : 'Nachrichtenvorlage bearbeiten'
                labelName = 'Titel';
                subjectField = <TextField size="small"
                                          value={this.state.item.subject}
                                          label='Betreff (maximal 255 Zeichen)' inputProps={{maxLength: 255}} variant="outlined"
                                          className='field'
                                          onChange={this.subjectOnChangeHandler}/>
                messageField = <TextField size="small"
                                          value={this.state.item.message} multiline maxRows={5}
                                          label='Nachricht (maximal 1000 Zeichen)' inputProps={{maxLength: 1000}} variant="outlined"
                                          className='textarea'
                                          onChange={this.messageOnChangeHandler}/>
                infoField = <p className="info-field">
                    In der Nachricht an den Geschäftspartner können variable Referenzen wie z.B. eine Dokumentennummer
                    aufgenommen werden. Dies kann durch die Bezeichnung in eckigen Klammern passieren (Beispiel:
                    [documentNr]). Diese wird dann in der Nachricht durch die richtige Dokumentennummer ersetzt.
                    Mögliche Referenzen sind:<br/><br/>

                    Dokumentennummer: [documentNr]<br/>
                    Dokumentendatum: [documentDate]<br/>
                    Bestellnummer: [orderNr]<br/>
                    Bestelldatum: [orderDate]<br/>
                    Kommissionsnummr: [commissionNr]<br/>
                    Kundennummer: [customerNr]<br/>
                    Kundenname: [customerName]<br/>
                    Lieferantennummer: [supplierNr]<br/>
                    Lieferantenname: [supplierName]<br/><br/>

                    Ist der Wert nicht vorhanden, bleibt der Platzhalter bestehen und kann vorm Versenden bearbeitet werden.
                </p>
                break;
            default:
                break;
        }

        return (
            <Dialog scroll='body' type={this.props.type} open={this.props.open} onClose={this.onClose} className='item-dialog'>

                <YesCancelDialog
                    open={this.state.showUnsavedChangesWarningDialog}
                    close={this.closeUnsavedChangesWarningDialog}
                    header='Verwerfen'
                    text='Ungespeicherte Änderungen gehen verloren. Wollen Sie fortfahren?'
                    onClick={() => this.close()}
                />

                <FileDialog
                    open={this.state.showUploadImageDialog}
                    close={this.closeUploadImageDialog}
                    showMessage={this.props.showMessage}
                    item={this.state.item}
                    uploadFile={this.addFileToItem}
                    type={2}
                />

                <div className='surrounder'>
                    <CloseIcon onClick={this.onClose} className='icon close-icon'/>
                    <DialogTitle className='title'>{title}</DialogTitle>
                    {idTextField}
                    {nrTextField}
                    <TextField size="small" value={this.state.item.name} onChange={this.nameOnChangeHandler} className='field' label={labelName} inputProps={{maxLength:50}}/>
                    {eanTextField}
                    {refNrTextField}
                    {articleGroupField}
                    {defaultSupplierField}
                    {pharmaFields}
                    {vatTextField}
                    {accountFields}
                    {executionDiv}
                    {pricingDiv}
                    {storageSpaceDiv}
                    {attributesDiv}
                    {unitTextField}
                    {dataTypes}
                    {paymentDiv}
                    {addressTextFields}
                    {subjectField}
                    {messageField}
                    {infoField}
                    <ToolButton main onClick={this.saveItemOnClickHandler} className='button'>{this.props.isNewItem ? 'Anlegen' : 'Speichern'}</ToolButton>
                </div>
            </Dialog>
        );
    }
}

export default ItemDialog;