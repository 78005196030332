import React, {Component} from "react";
import './SendEmailDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from 'react-tooltip'
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import api from "../../../../../../api";
import YesCancelDialog from "../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import MenuItem from "@mui/material/MenuItem";
import HelperFunctions from "../../../../../../reusable/HelperFunctions";

class SendEmailDialog extends Component {

    state = {
        emailInformation:{
            email:'',
            subject:'',
            message:this.props.user ? this.props.user.userInformation.emailDefaultMessage : "",
            signature:this.props.user ? this.props.user.userInformation.emailSignature : "",
            notes:'',
            emailAttachments:[ ...this.props.emailAttachments ],
            clearExhibitor:true
        },
        receiver:null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            let emailInformation = this.state.emailInformation;
            if(this.props.emailAttachments) {
                emailInformation.emailAttachments = this.props.emailAttachments;
            }
            this.setState({emailInformation:emailInformation,company:this.props.company});
        }
    }

    filter = createFilterOptions();

    subjectOnChangeHandler = (e) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.subject = e.target.value;
        this.setState({emailInformation:emailInformation});
    }

    messageOnChangeHandler = (e) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.message = e.target.value;
        this.setState({emailInformation:emailInformation});
    }

    toggleClearExhibitor = () => {
        let emailInformation = this.state.emailInformation;
        emailInformation.clearExhibitor = !emailInformation.clearExhibitor;
        this.setState({emailInformation:emailInformation});
    }

    receiverOnChangeHandler = (receiver) => {
        let emailInformation = this.state.emailInformation;
        if(receiver && receiver.addresses && receiver.addresses.length === 1 && receiver.addresses[0].email) {
            emailInformation.receiverEmail = receiver.addresses[0].email;
        }
        this.setState({receiver:receiver,emailInformation:emailInformation});
    }

    addressEmailOnChangeHandler = (email) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.receiverEmail = email;
        this.setState({emailInformation:emailInformation});
    }

    emailOnChangeHandler = (e) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.receiverEmail = e.target.value;
        this.setState({emailInformation:emailInformation});
    }

    messageTemplateOnChangeHandler = (e) => {
        console.log(this.props.emailReferencedOrder)
        let emailInformation = this.state.emailInformation;
        if(e.target.value.subject) {
            let formattedSubject = e.target.value.subject;
            if(this.props.emailReferencedOrder) {
                if(this.props.emailReferencedOrder.orderNr) {
                    formattedSubject = formattedSubject.replace("[orderNr]",this.props.emailReferencedOrder.orderNr);
                }
                if(this.props.emailReferencedOrder.orderDate) {
                    formattedSubject = formattedSubject.replace("[orderDate]",HelperFunctions.formatDate(this.props.emailReferencedOrder.orderDate));
                }
                if(this.props.emailReferencedOrder.commissionNr) {
                    formattedSubject = formattedSubject.replace("[commissionNr]",this.props.emailReferencedOrder.commissionNr);
                }
                if(this.props.emailReferencedOrder.assignedCustomer) {
                    if(this.props.emailReferencedOrder.assignedCustomer.nr) {
                        formattedSubject = formattedSubject.replace("[customerNr]",this.props.emailReferencedOrder.assignedCustomer.nr);
                    }
                    if(this.props.emailReferencedOrder.assignedCustomer.name) {
                        formattedSubject = formattedSubject.replace("[customerName]",this.props.emailReferencedOrder.assignedCustomer.name);
                    }
                }
                if(this.props.emailReferencedOrder.assignedSupplier) {
                    if(this.props.emailReferencedOrder.assignedSupplier.nr) {
                        formattedSubject = formattedSubject.replace("[supplierNr]",this.props.emailReferencedOrder.assignedSupplier.nr);
                    }
                    if(this.props.emailReferencedOrder.assignedSupplier.name) {
                        formattedSubject = formattedSubject.replace("[supplierNr]",this.props.emailReferencedOrder.assignedSupplier.name);
                    }
                }
            }
            if(emailInformation && emailInformation.emailAttachments && emailInformation.emailAttachments.length >= 1) {
                if(emailInformation.emailAttachments[0].documentNr) {
                    formattedSubject = formattedSubject.replace("[documentNr]",emailInformation.emailAttachments[0].documentNr);
                }
                if(emailInformation.emailAttachments[0].documentDate) {
                    formattedSubject = formattedSubject.replace("[documentDate]",HelperFunctions.formatDate(emailInformation.emailAttachments[0].documentDate));
                }
            }
            emailInformation.subject = formattedSubject;
        }else{
            emailInformation.subject = null;
        }
        if(e.target.value.message) {
            let formattedMessage = e.target.value.message;
            if(this.props.emailReferencedOrder) {
                if(this.props.emailReferencedOrder.orderNr) {
                    formattedMessage = formattedMessage.replace("[orderNr]",this.props.emailReferencedOrder.orderNr);
                }
                if(this.props.emailReferencedOrder.orderDate) {
                    formattedMessage = formattedMessage.replace("[orderDate]",HelperFunctions.formatDate(this.props.emailReferencedOrder.orderDate));
                }
                if(this.props.emailReferencedOrder.commissionNr) {
                    formattedMessage = formattedMessage.replace("[commissionNr]",this.props.emailReferencedOrder.commissionNr);
                }
                if(this.props.emailReferencedOrder.assignedCustomer) {
                    if(this.props.emailReferencedOrder.assignedCustomer.nr) {
                        formattedMessage = formattedMessage.replace("[customerNr]",this.props.emailReferencedOrder.assignedCustomer.nr);
                    }
                    if(this.props.emailReferencedOrder.assignedCustomer.name) {
                        formattedMessage = formattedMessage.replace("[customerName]",this.props.emailReferencedOrder.assignedCustomer.name);
                    }
                }
                if(this.props.emailReferencedOrder.assignedSupplier) {
                    if(this.props.emailReferencedOrder.assignedSupplier.nr) {
                        formattedMessage = formattedMessage.replace("[supplierNr]",this.props.emailReferencedOrder.assignedSupplier.nr);
                    }
                    if(this.props.emailReferencedOrder.assignedSupplier.name) {
                        formattedMessage = formattedMessage.replace("[supplierNr]",this.props.emailReferencedOrder.assignedSupplier.name);
                    }
                }
            }
            if(emailInformation && emailInformation.emailAttachments && emailInformation.emailAttachments.length >= 1) {
                if(emailInformation.emailAttachments[0].documentNr) {
                    formattedMessage = formattedMessage.replace("[documentNr]",emailInformation.emailAttachments[0].documentNr);
                }
                if(emailInformation.emailAttachments[0].documentDate) {
                    formattedMessage = formattedMessage.replace("[documentDate]",HelperFunctions.formatDate(emailInformation.emailAttachments[0].documentDate));
                }
            }
            emailInformation.message = formattedMessage;
        }else{
            emailInformation.message = null;
        }
        this.setState({messageTemplate:e.target.value,emailInformation:emailInformation});
    }

    signatureOnChangeHandler = (e) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.signature = e.target.value;
        this.setState({emailInformation:emailInformation});
    }

    notesOnChangeHandler = (e) => {
        let emailInformation = this.state.emailInformation;
        emailInformation.notes = e.target.value;
        this.setState({emailInformation:emailInformation});
    }

    sendEmailOnClickHandler = () => {
        if(this.state.emailInformation.receiverEmail && this.state.emailInformation.message && this.state.emailInformation.subject) {
            let sendWarning = false;
            for(let i = 0; i < this.state.emailInformation.emailAttachments.length; i++) {
                if(this.state.emailInformation.emailAttachments[i].sendWarning) {
                    sendWarning = true;
                    break;
                }
            }
            if(sendWarning) {
                this.showSendOriginalFileWarningDialog();
            }else{
                if(this.props.company && this.props.company.systemEmail) {
                    this.sendEmail(this.state.emailInformation);
                }else{
                    this.props.showMessage(2,"Sie müssen zunächst ein Postfach in den Einstellungen hinterlegen");
                }
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    sendEmail = (emailInformation) => {
        this.props.setLoading(true);
        const url = '/send-email';
        api.post(url,emailInformation)
            .then(response => {
                this.props.showMessage(0,'E-Mail erfolgreich versandt');
                this.props.addSentEmailToList(response.data);
                this.props.clearEmailAttachments();
                this.props.setEmailReferencedOrder(null);
                this.props.close();
                let emailInformation = {
                    receiverEmail:'',
                    subject:'',
                    message:this.props.company ? this.props.company.defaultMessage : "",
                    signature:this.props.user ? this.props.user.userInformation.emailSignature : "",
                    emailAttachments:[],
                    clearExhibitor:true
                };
                this.setState({emailInformation:emailInformation,receiver:null});
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    receivers = () => {
        return
    }

    //Dialog actinos

    showSendOriginalFileWarningDialog = () => {
        this.setState({showSendOriginalFileWarningDialog:true});
    }

    closeSendOriginalFileWarningDialog = () => {
        this.setState({showSendOriginalFileWarningDialog:false});
    }

    render() {
        return (
            <Dialog maxWidth="xl" scroll='body' open={this.props.open} onClose={this.props.close} className='send-email-dialog'>
                <Tooltip id="send-email-dialog-tooltip"/>
                <YesCancelDialog
                    open={this.state.showSendOriginalFileWarningDialog}
                    close={this.closeSendOriginalFileWarningDialog}
                    header='Original-Datei versenden'
                    text='Unter den Anhängen befindet sich eine Original-Datei. Wollen Sie diese wirklich versenden?'
                    onClick={() => this.sendEmail(this.state.emailInformation)}
                />

                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle>E-Mail versenden</DialogTitle>
                    <div className="receiver-informations">
                        <Autocomplete
                            size="small"
                            disablePortal
                            id="receivers"
                            onChange={(e,newValue) => this.receiverOnChangeHandler(newValue)}
                            className='field'
                            options={this.props.customers.concat(this.props.suppliers)}
                            filterOptions={(options, params) => {
                                const filtered = this.filter(options, params);
                                return filtered.slice(0, 10);
                            }}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField size="small" {...params} label="Geschäftspartner" InputLabelProps={{shrink: true}}/>}
                        />
                        <Autocomplete
                            size="small"
                            value={this.state.emailInformation && this.state.emailInformation.receiverEmail ? this.state.emailInformation.receiverEmail : null}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.addressEmailOnChangeHandler({
                                        email: newValue,
                                    });
                                } else if (newValue && newValue.inputValue && newValue.inputValue.email) {
                                    // Create a new value from the user input
                                    this.addressEmailOnChangeHandler({
                                        email: newValue.inputValue.email,
                                    });
                                } else if(newValue && newValue.email){
                                    this.addressEmailOnChangeHandler(newValue.email);
                                } else {
                                    this.addressEmailOnChangeHandler(null);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = this.filter(options, params);
                                return filtered.slice(0, 10);
                            }}
                            selectOnFocus
                            clearOnBlur={false}
                            clearOnEscape={false}
                            handleHomeEndKeys
                            id="customers"
                            className='field'
                            options={this.state.receiver && this.state.receiver.addresses ? this.state.receiver.addresses : []}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.email;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.email}</li>}
                            freeSolo
                            renderInput={(params) => (
                                <TextField onChange={this.emailOnChangeHandler} size="small" {...params} inputProps={{ ...params.inputProps, maxLength: 55 }} label="Empfänger E-Mail" InputLabelProps={{shrink: true}}/>
                            )}
                        />
                    </div>
                    <TextField select size="small" label="Nachrichtenvorlage" onChange={this.messageTemplateOnChangeHandler} InputLabelProps={{shrink: true}} className="field" value={this.state.messageTemplate ? this.state.messageTemplate : null}>
                        {this.props.messageTemplates ? this.props.messageTemplates.map(messageTemplate => (
                            <MenuItem value={messageTemplate}>{messageTemplate.name}</MenuItem>
                        )) : null}
                    </TextField>
                    <TextField size="small" onChange={this.subjectOnChangeHandler} InputLabelProps={{shrink: true}} value={this.state.emailInformation.subject} className='field subject' label='Betreff'/>
                    <TextField size="small" onChange={this.messageOnChangeHandler} InputLabelProps={{shrink: true}} value={this.state.emailInformation.message} label="Standard Nachricht" helperText='maximal 1000 Zeichen' multiline className='field message-input' rows={5} maxRows={20}/>
                    <TextField size="small" onChange={this.signatureOnChangeHandler} InputLabelProps={{shrink: true}} value={this.state.emailInformation.signature} label="Signatur" helperText='maximal 1000 Zeichen' multiline className='field message-input' rows={5} maxRows={20}/>
                    {this.props.emailReferencedOrder && (this.props.emailReferencedOrder.orderNr || this.props.emailReferencedOrder.commissionNr) ? <p className="attachment-info">Referenzierte Bestellung: {this.props.emailReferencedOrder.orderNr ? "Bestellnummer " + this.props.emailReferencedOrder.orderNr + " " : null}{this.props.emailReferencedOrder.commissionNr ? "Kommissionsnummer " + this.props.emailReferencedOrder.commissionNr : null} <CloseIcon className="icon remove-icon" onClick={() => this.props.setEmailReferencedOrder(null)}/></p> : null}
                    <p className="attachment-info">
                        {this.state.emailInformation && this.state.emailInformation.emailAttachments && this.state.emailInformation.emailAttachments.length > 0 ?
                            "Im Anhang:" : "Kein Anhang"}
                    </p>
                    <div className="attachments-holder">
                        <ul>
                            {this.state.emailInformation.emailAttachments.map(emailAttachment => (
                                <li>
                                    <p className='attachment'>{emailAttachment.name ? emailAttachment.name : null}</p>
                                    {emailAttachment.sendWarning ? <WarningIcon className='icon warning-icon' data-tooltip-id="send-email-dialog-tooltip" data-tooltip-place="top" data-tooltip-content='Dies ist eine Original-Datei'/> : null}
                                    <CloseIcon onClick={() => this.props.removeEmailAttachment(emailAttachment)} className='icon remove-icon'/>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <ToolButton main className='button' onClick={this.sendEmailOnClickHandler}>Versenden</ToolButton>
                </div>
            </Dialog>
        )
    }
}

export default SendEmailDialog;