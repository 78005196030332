import React, {Component} from "react";
import './Batch.css';

//Components
import TextField from "@mui/material/TextField";
import { Tooltip } from 'react-tooltip'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import EmailIcon from "@mui/icons-material/Email";

class Batch extends Component {
    render() {

        let disabledStyle = null;

        if(this.props.locked) {
            disabledStyle = {
                color:"gray"
            }
        }

        return (
            <Paper elevation={3} className='batch'>
                <Tooltip id="batch-tooltip"/>
                <CloseIcon style={disabledStyle} onClick={!this.props.locked ? () => this.props.removeBatch(this.props.index) : null} className='icon close-icon'/>
                <h3>Charge</h3>
                <TextField disabled={this.props.locked} size="small" value={this.props.batch ? this.props.batch.nr : ''} label='Chargennummer' className='field' onChange={(e) => this.props.batchNrOnChangeHandler(e,this.props.index)}/>
                <TextField disabled={this.props.locked} size="small" value={this.props.batch ? this.props.batch.material : ''} label='Werkstoff' helperText='optional' className='field' onChange={(e) => this.props.batchMaterialOnChangeHandler(e,this.props.index)}/>
                <Paper elevation={3} className='factory-certificate-panel'>
                    <div className='top-field'>
                        <h3 className='expand-panel-title'>Werkszeugnis</h3>
                        {!this.props.batch.factoryCertificate ?
                            <AddIcon style={disabledStyle} onClick={!this.props.locked ? () => this.props.batchAddFactoryCertificate(this.props.index) : null} className='add-remove-icon'/> :
                            !this.props.batch.factoryCertificate.id ? <DeleteIcon style={disabledStyle} onClick={!this.props.locked ? () => this.props.batchRemoveFactoryCertificate(this.props.index) : null} className='add-remove-icon' /> : null}
                    </div>
                    {this.props.batch && this.props.batch.factoryCertificate ?
                        <div className='icon-holder-surrounder'>
                            <div className='icon-holder'>
                                <p>Original Datei</p>
                                {this.props.batch.factoryCertificate.id ? <UploadFileIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content="Original Datei anhängen" onClick={!this.props.locked ? () => this.props.showOriginalFileDialog(this.props.batch.factoryCertificate,this.props.index) : null} className='icon edit-icon'/> : null}
                                {this.props.batch.factoryCertificate.originalFileStorageLocation ? <PictureAsPdfIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.batch.factoryCertificate.originalFileStorageLocation.name + " anzeigen"} onClick={() => this.props.showPDFDialog(this.props.batch.factoryCertificate.originalFileStorageLocation)} className='icon display-pdf-icon'/> : ''}
                                {this.props.batch.factoryCertificate.originalFileStorageLocation ? <EmailIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content="Als E-Mail versenden" onClick={!this.props.locked ? () => this.props.addEmailAttachment(this.props.batch.factoryCertificate.originalFileStorageLocation,true,null) : null} className='icon mail-icon'/> : null}
                                {this.props.batch.factoryCertificate.originalFileStorageLocation ? <DeleteIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.batch.factoryCertificate.originalFileStorageLocation.name + " entfernen"} onClick={!this.props.locked ? () => this.props.showDeleteOriginalFileDialog(this.props.index) : null} className='icon delete-icon'/> : ''}
                            </div>
                            <div className='icon-holder'>
                                <p>Bearbeitete Datei</p>
                                {this.props.batch.factoryCertificate.id ? <UploadFileIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content="Bearbeitete Datei anhängen" onClick={!this.props.locked ? () => this.props.showEditedFileDialog(this.props.batch.factoryCertificate,this.props.index) : null} className='icon edit-icon'/> : null}
                                {this.props.batch.factoryCertificate.editedFileStorageLocation ? <PictureAsPdfIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.batch.factoryCertificate.editedFileStorageLocation.name + " anzeigen"} onClick={() => this.props.showPDFDialog(this.props.batch.factoryCertificate.editedFileStorageLocation)} className='icon display-pdf-icon'/> : ''}
                                {this.props.batch.factoryCertificate.editedFileStorageLocation ? <EmailIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content="Als E-Mail versenden" onClick={!this.props.locked ? () => this.props.addEmailAttachment(this.props.batch.factoryCertificate.editedFileStorageLocation,false,null) : null} className='icon mail-icon'/> : null}
                                {this.props.batch.factoryCertificate.editedFileStorageLocation ? <DeleteIcon data-tooltip-id="batch-tooltip" data-tooltip-place="top" data-tooltip-content={this.props.batch.factoryCertificate.editedFileStorageLocation.name + " entfernen"} onClick={!this.props.locked ? () => this.props.showDeleteEditedFileDialog(this.props.index) : null} className='icon delete-icon'/> : ''}
                            </div>
                            <FormControlLabel style={disabledStyle} disabled={this.props.locked} className='field' control={<Checkbox onChange={() => this.props.batchToggleFactoryCertificateADW2(this.props.index)} checked={this.props.batch.factoryCertificate.adw2} />} label="AD W2" />
                            <TextField disabled={this.props.locked} size="small" value={this.props.batch.factoryCertificate.folderStorage} label='Ordnerablage' helperText='optional' className='field' onChange={(e) => this.props.batchFactoryCertificateFolderStorageOnChangeHandler(e,this.props.index)}/>
                        </div> : null}
                </Paper>
            </Paper>
        )
    }
}

export default Batch;