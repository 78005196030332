import React, {Component} from 'react';
import './Orders.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolFab from "../../../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@mui/icons-material/Add';
import ToolButton from "../../../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import YesCancelDialog from "../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import FileDialog from "../../../../../../../reusable/FileDialog/FileDialog";
import ListItem from "../../../../../../../reusable/ListItem/ListItem";
import api from "../../../../../../../api";
import OrderDialog from "./OrderDialog/OrderDialog";
import SendEmailDialog from "../../../EmailPanel/SendEmailDialog/SendEmailDialog";
import {UploadFile} from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterDialog from "./FilterDialog/FilterDialog";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import GradeIcon from '@mui/icons-material/Grade';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecureYesCancelDialog from "../../../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import PositionOverviewItem from "../../../../../../../reusable/PositionOverviewItem/PositionOverviewItem";
import Paper from "@mui/material/Paper";
import PositionDialog from "./PositionDialog/PositionDialog";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import jsPDF from "jspdf";
import HelperFunctions from "../../../../../../../reusable/HelperFunctions";
import helperFunctions from "../../../../../../../reusable/HelperFunctions";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from 'react-tooltip'
import _ from "lodash";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from "@mui/icons-material/Download";
import ExportFilesDialog from "./ExportFilesDialog/ExportFilesDialog";
import OrderItem from "./OrderItem/OrderItem";

class Orders extends Component {

    state = {
        activeItem:null,
        activePosition:null,
        file:null,
        shownItems:[],
        searchWord:'',
        searchType:'free-search',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        searchedAttribute:null,
        loadingSearch:false,
        filters:this.props.filters,
        activeFilter:null,
        filter:null,
        sort:null,
        positionDisplay:false,

        //Dialogs
        showItemDialog:false,
        showUploadDialog:false,
        showDeleteItemDialog:false,
        showImportDataDialog:false,
        showInfoDialog:false,
        showFileDialog:false,
        showSendMailDialog:false,
        showFilterDialog:false,
        showExportFilesDialog:false
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.searchOnClick();
        }
    }

    componentDidMount() {
        let defaultFilter = this.props.kind === 0 ? this.props.user.userInformation.defaultCustomerOrderFilter : this.props.kind === 1 ? this.props.user.userInformation.defaultSupplierOrderFilter : null;
        this.setState({activeFilter:defaultFilter});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.filters !== this.props.filters) {
            this.setState({filters:this.props.filters});
        }
    }

    loadUrgentOrders = (displayItems) => {
        if(displayItems) {
            this.setLoadingSearch(true);
            this.setState({shownItems:[]});
        }
        let url;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        url = url + "/search/urgent";
        api.post(url)
            .then(response => {
                if(response.data.length > 0) {
                    this.setState({shownItems:response.data});
                }else{
                    this.props.showMessage(1,"Es wurden keine Bestellungen gefunden");
                }
                this.setLoadingSearch(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
        return null;
    }

    setActiveItem = (item) => {
        this.setState({activeItem:item});
    }

    setIsNewItem = (value) => {
        this.setState({isNewItem:value});
    }

    togglePositionDisplay = () => {
        this.setState({shownItems:[],positionDisplay:!this.state.positionDisplay});
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    customerOnChange = (customer) => {
        this.setState({searchWord:customer.id});
    }

    supplierOnChange = (supplier) => {
        this.setState({searchWord:supplier.id});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType && (this.state.searchType === "free-search" || this.state.searchWord)) {
            this.setState({shownItems:[]});
            this.setLoadingSearch(true);
            this.loadOrders();
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche und ein Suchwort aus');
        }
    }

    setLoadingSearch = (loadingState) => {
        this.setState({loadingSearch:loadingState});
    }

    activeFilterOnChangeHandler = (e) => {
        if(e.target.value && e.target.value !== -1) {
            for(let i = 0; i < this.state.filters.length; i++) {
                if(e.target.value === this.state.filters[i].id) {
                    this.setState({activeFilter:this.state.filters[i]});
                    return;
                }
            }
        }else{
            this.setState({activeFilter:null});
        }
    }

    addItemToList = (item) => {
        this.setState(prevState => ({
            shownItems: [item, ...prevState.shownItems]
        }));
    }

    updateItemInList = (item) => {
        let shownItems = this.state.shownItems;
        for(let i = 0; i < shownItems.length; i++) {
            if(item.id === shownItems[i].id) {
                shownItems[i] = item;
                break;
            }
        }
        this.setState({shownItems:shownItems});
    }

    removeItemFromList = (item) => {
        let shownItems = [...this.state.shownItems];
        for(let i = 0; i < shownItems.length; i++) {
            if (shownItems[i].id === item.id) {
                shownItems.splice(i,1)
                this.setState({shownItems:shownItems});
                return;
            }
        }
    }

    uploadFile = (multipartFile) => {
        let url = this.props.kind === 0 ? "/customer-orders/read-pdf" : "/supplier-orders/read-pdf";
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.post(url,formData)
            .then(response => {
                this.addItemToList(response.data);
                this.showItemDialog(false,response.data);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    addNewFileToItem = (item,multipartFile) => {
        let url;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.post(url + "/" + item.id,formData)
            .then(response => {
                let activeItem = this.state.activeItem;
                activeItem.fileStorageLocation = response.data;
                this.setState({activeItem:activeItem});
                this.props.showMessage(0,'Datei erfolgreich angehängt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    removeFile = (item) => {
        let url;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        api.put(url + "/remove",item)
            .then(response => {
                let activeItem = this.state.activeItem;
                activeItem.fileStorageLocation = response.data;
                this.setState({activeItem:activeItem});
                this.props.showMessage(0,'Datei erfolgreich entfernt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    addMultipleEntries = (accountEntryList,order) => {
        let data = {
            createEntries:accountEntryList
        }
        const url = '/accounts';
        api.put(url,data)
            .then(response => {
                if(order) {
                    this.updateItem(order,true);
                }
                this.props.updateMultipleAccountsOnList(response.data);
                this.props.showMessage(0,'Transaktionen (Buchhaltung) erfolgreich verbucht');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    setDocumentPositionPositions = (item) => {
        let setPositions = false;
        if(item.documentPositions[0] && !item.documentPositions[0].position) {
            setPositions = true;
        }
        if(setPositions) {
            for(let i = 0; i < item.documentPositions.length; i++) {
                item.documentPositions[i].position = i + 1;
            }
        }
        return item;
    }

    saveFilterOnClickHandler = (filter) => {
        if(this.validateFilter(filter)) {
            if(filter.id) {
                this.updateFilter(filter)
            }else{
                this.createNewFilter(filter);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    validateFilter = (filter) => {
        if(!filter.name) {
            return false;
        }
        if((filter.periodAsDate && (!filter.fromDate || !filter.toDate)) || (!filter.periodAsDate && (filter.fromDays === null || filter.toDays === null || filter.fromDays === "" || filter.toDays === ""))) {
            return false;
        }
        if(!filter.filterConditions) {
            return false;
        }
        for(let i = 0; i < filter.filterConditions.length; i++) {
            if(!filter.filterConditions[i].filterKind) {
                return false;
            }
            switch (filter.filterConditions[i].filterKind) {
                case "STATUS":
                    if(!filter.filterConditions[i].orderState) {
                        return false;
                    }
                    break;
                case "ARTICLE":
                    if(!filter.filterConditions[i].article) {
                        return false;
                    }
                    break;
                case "CUSTOMER":
                    if(!filter.filterConditions[i].customer) {
                        return false;
                    }
                    break;
                case "SUPPLIER":
                    if(!filter.filterConditions[i].supplier) {
                        return false;
                    }
                    break;
                case "COMMISSION_NR":
                    if(!filter.filterConditions[i].commissionNr) {
                        return false;
                    }
                    break;
            }
        }
        return true;
    }

    createNewFilter = (filter) => {
        const url = "/filters";
        api.post(url,filter)
            .then(response => {
                this.props.addFilterToList(response.data);
                this.props.showMessage(0,"Der Filter wurde erfolgreich gespeichert");
                this.closeFilterDialog();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    updateFilter = (filter) => {
        const url = "/filters";
        api.put(url,filter)
            .then(response => {
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                this.closeFilterDialog();
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    defaultFilterOnClickHandler = (filterId) => {
        let defaultFilterId = -1
        if(filterId) {
            defaultFilterId = filterId;
        }
        const url = this.props.kind === 0 ? "/users/customer-order-filter/" + defaultFilterId : "/users/supplier-order-filter/" + defaultFilterId;
        api.put(url)
            .then(response => {
                if(this.props.kind === 0) {
                    this.props.updateDefaultCustomerOrderFilter(response.data);
                }else if(this.props.kind === 1) {
                    this.props.updateDefaultSupplierOrderFilter(response.data);
                }
                this.props.showMessage(0,"Default Filter erfolgreich gesetzt");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    deleteFilter = (filter) => {
        const url = "/filters/" + filter.id;
        api.delete(url)
            .then(response => {
                this.props.removeFilterFromList(filter);
                this.setState({filters:this.props.filters});
                this.props.showMessage(0,"Der Filter wurde erfolgreich gelöscht");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    lockOrder = (order) => {
        const url = this.props.kind === 0 ? "/customer-orders/lock/" + order.id : "/supplier-orders/lock/" + order.id;
        api.put(url);
    }

    unlockOrder = (order) => {
        const url = this.props.kind === 0 ? "/customer-orders/unlock/" + order.id : "/supplier-orders/unlock/" + order.id;
        api.put(url);
    }

    sortOnClickHandler = (sort) => {
        this.setState({sort:sort});
    }

    sortItems = (items) => {
        items.sort((a,b) => {
            switch (this.state.sort) {
                case "invoiceDate":
                    return ((b.invoiceDate ? new Date(b.invoiceDate) : 0) - (a.invoiceDate ? new Date(a.invoiceDate) : 0));
                case "deliveryDate":
                    return ((b.deliveryDate ? new Date(b.deliveryDate) : 0) - (a.deliveryDate ? new Date(a.deliveryDate) : 0));
                case "commissionNr":
                    return (b.commissionNr || 0) - (a.commissionNr || 0);
                case "item":
                    return a.item.localeCompare(b.item);
                case "orderNr":
                    return a.orderNr.localeCompare(b.orderNr);
                case "customer":
                    return a.assignedCustomer.name.localeCompare(b.assignedCustomer.name);
                case "supplier":
                    return a.assignedSupplier.name.localeCompare(b.assignedSupplier.name);
                case "note":
                    if (a.note === null || a.note === undefined) {
                        return 1;
                    }
                    if (b.note === null || b.note === undefined) {
                        return -1;
                    }
                    return a.note.localeCompare(b.note);
                default:
                    return 0;
            }
        })
    }

    savePosition = (position) => {
        api.put("/document-positions",position)
            .then(response => {
                try{
                    let shownItems = this.state.shownItems;
                    for(let i = 0; i < shownItems.length; i++) {
                        for(let j = 0; j < shownItems[i].documentPositions.length; j++) {
                            if(shownItems[i].documentPositions[j].id === response.data.id) {
                                shownItems[i].documentPositions[j] = response.data;
                                this.setState({shownItems:shownItems});
                                this.props.showMessage(0,"Die Position wurde erfolgreich gespeichert");
                                return;
                            }
                        }
                    }
                }catch (ignore) {

                }
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    MAX_LINE_LENGTH = 115;
    POSITIONS_START_Y = 70;
    LINE_HEIGHT = 8;
    PAGE_LINES = 40;
    LEFT_BORDER = 40;
    KOMM_NR_X = 100;
    ORDER_NR_X = 160;
    BUSINESS_PARTNER_X = 220;
    ORDER_DAT_X = 280;
    DELIVERY_DAT_X = 340;
    ITEM_X = 400;
    LINE_OFFSET = 4;

    generateDeliverySchedule = () => {
        let orders = this.sortedItems();

        //format: ~445 x 630
        let pdf = new jsPDF('l','px');
        pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular-normal', 'normal');
        pdf.setFont('OpenSans-Regular');

        let availableLines = this.PAGE_LINES;
        let usedLines = 2;

        this.initializeCommonDocumentData(pdf,true);

        for(let i = orders.length - 1; i >= 0; i--) {
            if(orders[i].note) {
                orders[i].formattedNote = orders[i].note.split('\n');
                for(let j = 0; j < orders[i].formattedNote.length; j++) {
                    if(orders[i].formattedNote[j].length > this.MAX_LINE_LENGTH) {
                        orders[i].formattedNote.push(orders[i].formattedNote[j].substring(this.MAX_LINE_LENGTH))
                        orders[i].formattedNote[j] = orders[i].formattedNote[j].substring(0,this.MAX_LINE_LENGTH);
                    }
                }
            }else{
                orders[i].formattedNote = null;
            }
        }

        for(let i = 0; i < orders.length; i++) {
            // Add Header if needed

            // New Page
            if((orders[i].note && (availableLines - usedLines) < 3) || (!orders[i].note && (availableLines - usedLines) < 2)) {
                this.initializeCommonDocumentData(pdf,false);
                usedLines = 2;
            }

            pdf.text("________________________________________________________________________________________________________________________________________________________________",this.LEFT_BORDER,this.POSITIONS_START_Y + (this.LINE_HEIGHT * usedLines) - this.LINE_OFFSET);
            usedLines++;
            let statusText;
            switch (orders[i].orderState) {
                case "NO_STATE":
                    statusText = "-";
                    break;
                case "OFFER_CREATION":
                    statusText = "Angebotserstellung";
                    break;
                case "NOT_YET_APPROVED":
                    statusText = "Noch nicht freigegeben";
                    break;
                case "WORK_IN_PROGRESS":
                    statusText = "In Bearbeitung";
                    break;
                case "COMPLETED":
                    statusText = "Fertiggestellt";
                    break;
                case "REQUESTED":
                    statusText = "Angefragt";
                    break;
                case "CONFIRMED":
                    statusText = "Bestätigt";
                    break;
                case "REJECTED":
                    statusText = "Abgelehnt";
                    break;
                case "GOODS_RECEIVED":
                    statusText = "Ware erhalten";
                    break;
                default:
                    statusText = "-";
                    break;
            }
            let commissionNr = orders[i].commissionNr ? helperFunctions.formatString(orders[i].commissionNr.toString(),12) : "-";
            let orderNr = orders[i].orderNr ? helperFunctions.formatString(orders[i].orderNr.toString(),12) : "-";
            let businessPartnerName = null;
            if(this.props.kind === 0) {
                businessPartnerName = orders[i].assignedCustomer && orders[i].assignedCustomer.name ? helperFunctions.formatString(orders[i].assignedCustomer.name,12) : "-";
            }else if(this.props.kind === 1) {
                businessPartnerName = orders[i].assignedSupplier && orders[i].assignedSupplier.name ? helperFunctions.formatString(orders[i].assignedSupplier.name,12) : "-";
            }
            let orderDate = orders[i].orderDate ? helperFunctions.formatDate(orders[i].orderDate.toString()) : "-";
            let deliveryDate = orders[i].deliveryDate ? helperFunctions.formatDate(orders[i].deliveryDate.toString()) : "-";
            let item = orders[i].item ? helperFunctions.formatString(orders[i].item,40) : "-";

            pdf.text(helperFunctions.formatString(statusText,14),this.LEFT_BORDER,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(commissionNr,this.KOMM_NR_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(orderNr,this.ORDER_NR_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(businessPartnerName,this.BUSINESS_PARTNER_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(item,this.ITEM_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(orderDate,this.ORDER_DAT_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);
            pdf.text(deliveryDate,this.DELIVERY_DAT_X,this.POSITIONS_START_Y + this.LINE_HEIGHT * usedLines);

            usedLines++;
            if(orders[i].formattedNote) {
                pdf.setTextColor("gray");
                for(let j = 0; j < orders[i].formattedNote.length; j++) {
                    if((availableLines - usedLines) > 0) {
                        pdf.text(orders[i].formattedNote[j],this.LEFT_BORDER,this.POSITIONS_START_Y + (this.LINE_HEIGHT * usedLines));
                        usedLines++;
                    }else{
                        // New Page
                        this.initializeCommonDocumentData(pdf,false);
                        pdf.setTextColor("black");
                        usedLines = 2;
                        pdf.setTextColor("gray");
                        pdf.text(orders[i].formattedNote[j],this.LEFT_BORDER,this.POSITIONS_START_Y + (this.LINE_HEIGHT * usedLines));
                        usedLines++;
                    }
                }
                pdf.setTextColor("black");
            }
        }
        pdf.save("lieferterminliste_" + new Date().toISOString().substring(0,10));
    }

    initializeCommonDocumentData = (pdf,firstPage) => {
        if(!firstPage) {
            pdf.addPage('string','l');
        }
        pdf.setTextColor("black");
        pdf.setFontSize(16);
        pdf.setFont('OpenSans-Bold','normal');
        pdf.text("Lieferterminliste",40,50);

        pdf.setFont('OpenSans-Regular','normal');
        pdf.setFontSize(10);

        pdf.text("Status",this.LEFT_BORDER,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        pdf.text("Komm.-Nr.",this.KOMM_NR_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        pdf.text("Best.-Nr.",this.ORDER_NR_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        let businessPartnerText = "";
        if(this.props.kind === 0) {
            businessPartnerText = "Kunde";
        }else if(this.props.kind === 1) {
            businessPartnerText = "Lieferant";
        }
        pdf.text(businessPartnerText,this.BUSINESS_PARTNER_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        pdf.text("Best.-Dat.",this.ORDER_DAT_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        pdf.text("Lief.-Dat.",this.DELIVERY_DAT_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);
        pdf.text("Gegenstand",this.ITEM_X,this.POSITIONS_START_Y + this.LINE_HEIGHT);

        pdf.text(HelperFunctions.formatDate(new Date().toISOString()),540,50);

        pdf.text('Seite ' + 1,550,410);
    }

    sortedPositions = () => {
        let documentPositions = [];
        let sortedItems = this.sortedItems();
        for(let i = 0; i < sortedItems.length; i++) {
            for(let j = 0; j < sortedItems[i].documentPositions.length; j++) {
                if((this.props.kind === 0 && (
                        sortedItems[i].documentPositions[j].state === "OFFER_CREATION" ||
                        sortedItems[i].documentPositions[j].state === "NOT_YET_APPROVED" ||
                        sortedItems[i].documentPositions[j].state === "WORK_IN_PROGRESS"))
                    || (this.props.kind === 1 && (
                        sortedItems[i].documentPositions[j].state === "REQUESTED" ||
                        sortedItems[i].documentPositions[j].state === "CONFIRMED" ||
                        sortedItems[i].documentPositions[j].state === "REJECTED"))) {
                    let documentPosition = sortedItems[i].documentPositions[j];
                    documentPosition.locked = sortedItems[i].locked;
                    documentPosition.commissionNr = sortedItems[i].commissionNr;
                    documentPosition.orderNr = sortedItems[i].orderNr;
                    documentPosition.orderDate = sortedItems[i].orderDate;
                    documentPosition.item = sortedItems[i].item;
                    if(!documentPosition.deliveryDate) {
                        documentPosition.deliveryDate = sortedItems[i].deliveryDate;
                    }
                    if(this.props.kind === 0) {
                        documentPosition.assignedCustomer = sortedItems[i].assignedCustomer;
                    }else if(this.props.kind === 1) {
                        documentPosition.assignedSupplier = sortedItems[i].assignedSupplier;
                    }

                    documentPositions.push(documentPosition);
                }
            }
        }
        return documentPositions.sort((a, b) => {
            switch (this.state.sort) {
                case "deliveryDate":
                    return ((b.deliveryDate ? new Date(b.deliveryDate) : 0) - (a.deliveryDate ? new Date(a.deliveryDate) : 0));
                case "orderDate":
                    return ((b.orderDate ? new Date(b.orderDate) : 0) - (a.orderDate ? new Date(a.orderDate) : 0));
                case "commissionNr":
                    return (b.commissionNr || 0) - (a.commissionNr || 0);
                case "customer":
                    let customerA = a.assignedCustomer && a.assignedCustomer.name ? a.assignedCustomer.name : "";
                    let customerB = b.assignedCustomer && b.assignedCustomer.name ? b.assignedCustomer.name : "";
                    return customerA.localeCompare(customerB);
                case "supplier":
                    let supplierA = a.assignedSupplier && a.assignedSupplier.name ? a.assignedSupplier.name : "";
                    let supplierB = b.assignedSupplier && b.assignedSupplier.name ? b.assignedSupplier.name : "";
                    return supplierA.localeCompare(supplierB);
                case "article":
                    let articleA = a.article && a.article.name ? a.article.name : "";
                    let articleB = b.article && b.article.name ? b.article.name : "";
                    return articleA.localeCompare(articleB);
                case "note":
                    if (a.note === null || a.note === undefined) {
                        return 1;
                    }
                    if (b.note === null || b.note === undefined) {
                        return -1;
                    }
                    return a.note.localeCompare(b.note);
                default:
                    return 0;
            }
        });
    }

    sortedItems = () => {
        return this.state.shownItems.sort((a, b) => {
            switch (this.state.sort) {
                case "deliveryDate":
                    return ((b.deliveryDate ? new Date(b.deliveryDate) : 0) - (a.deliveryDate ? new Date(a.deliveryDate) : 0));
                case "orderDate":
                    return ((b.orderDate ? new Date(b.orderDate) : 0) - (a.orderDate ? new Date(a.orderDate) : 0));
                case "commissionNr":
                    return (b.commissionNr || 0) - (a.commissionNr || 0);
                case "item":
                    if (a.item === null || a.item === undefined) {
                        return 1;
                    }
                    if (b.item === null || b.item === undefined) {
                        return -1;
                    }
                    return a.item.localeCompare(b.item);
                case "orderNr":
                    if (a.orderNr === null || a.orderNr === undefined) {
                        return 1;
                    }
                    if (b.orderNr === null || b.orderNr === undefined) {
                        return -1;
                    }
                    return b.orderNr.localeCompare(a.orderNr);
                case "customer":
                    return a.assignedCustomer.name.localeCompare(b.assignedCustomer.name);
                case "supplier":
                    return a.assignedSupplier.name.localeCompare(b.assignedSupplier.name);
                case "note":
                    if (a.note === null || a.note === undefined) {
                        return 1;
                    }
                    if (b.note === null || b.note === undefined) {
                        return -1;
                    }
                    return a.note.localeCompare(b.note);
                default:
                    return 0;
            }
        })
    }

    loadOrders = () => {
        let url;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        url = url + "/search/" + this.state.searchType;
        if(this.state.searchWord) {
            url = url + "/" + this.state.searchWord;
        }
        let filter = this.state.activeFilter ? this.state.activeFilter : {filter:null};
        api.post(url,filter)
            .then(response => {
                if(response.data.length > 0) {
                    this.setState({shownItems:response.data});
                }else{
                    this.props.showMessage(1,"Es wurden keine Bestellungen gefunden");
                }
                this.setLoadingSearch(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    deleteItem = (item) => {
        this.props.setLoading(true);
        let url;
        let message;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                message = 'Kundenbestellung erfolgreich gelöscht';
                break;
            case 1:
                url = '/supplier-orders';
                message = 'Lieferantenbestellung erfolgreich gelöscht';
                break;
            default:
                break;
        }
        api.delete(url + "/" + item.id)
            .then(response => {
                this.props.showMessage(0,message);
                this.removeItemFromList(item);
                this.setState({activeItem:null});
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    commissionNrOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].commissionNr = e.target.value;
        this.setState({shownItems:orders});
    }

    orderNrOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].orderNr = e.target.value;
        this.setState({shownItems:orders});
    }

    itemOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].item = e.target.value;
        this.setState({shownItems:orders});
    }

    orderDateOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].orderDate = e.target.value;
        this.setState({shownItems:orders});
    }

    deliveryDateOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].deliveryDate = e.target.value;
        this.setState({shownItems:orders});
    }

    noteOnChangeHandler = (e,index) => {
        let orders = [ ...this.state.shownItems ];
        orders[index].note = e.target.value;
        this.setState({shownItems:orders});
    }

    updateOrder = (item) => {
        this.props.setLoading(true);
        let url;
        switch (this.props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        api.put(url,item)
            .then(response => {
                this.props.showMessage(0,"Änderungen erfolgreich gespeichert");
                this.updateItemInList(response.data);
                this.props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    this.props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    this.props.logout();
                }
                this.props.setLoading(false);
            });
    }

    //Dialog actions
    showItemDialog = (isNewItem,item) => {
        let copyItem = _.cloneDeep(item);
        copyItem.documentPositions = _.cloneDeep(copyItem.documentPositions);
        copyItem.documentPositions.sort((a,b) => a.position - b.position);
        if(copyItem.id && !copyItem.locked) {
            this.lockOrder(copyItem);
        }
        this.setState({isNewItem:isNewItem,activeItem:this.setDocumentPositionPositions(copyItem),showItemDialog:true});
    }

    closeItemDialog = () => {
        if(this.state.activeItem.id) {
            this.unlockOrder(this.state.activeItem);
        }
        this.setState({showItemDialog:false,activeItem:null});
    }

    showUploadDialog = () => {
        this.setState({showUploadDialog:true});
    }

    closeUploadDialog = () => {
        this.setState({showUploadDialog:false});
    }

    showDeleteItemDialog = (item) => {
        this.setState({activeItem:item,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    showImportDataDialog = () => {
        this.setState({showImportDataDialog:true});
    }

    closeImportDataDialog = () => {
        this.setState({showImportDataDialog:false});
    }

    showFileDialog = (item) => {
        this.setState({activeItem:item,showFileDialog:true});
    }

    closeFileDialog = () => {
        this.setState({showFileDialog:false});
    }

    showRemoveFileDialog = (item) => {
        this.setState({activeItem:item,showRemoveFileDialog:true});
    }

    closeRemoveFileDialog = () => {
        this.setState({showRemoveFileDialog:false});
    }

    showSendMailDialog = (item) => {
        this.setState({activeItem:item,showSendMailDialog:true});
    }

    closeSendMailDialog = () => {
        this.setState({showSendMailDialog:false});
    }

    showFilterDialog = (filter) => {
        this.setState({filter:filter,showFilterDialog:true});
    }

    closeFilterDialog = () => {
        this.setState({showFilterDialog:false});
    }

    showDeleteFilterDialog = (filter) => {
        this.setState({filter:filter,showDeleteFilterDialog:true});
    }

    closeDeleteFilterDialog = () => {
        this.setState({filter:null,showDeleteFilterDialog:false});
    }

    showPositionDialog = (position) => {
        this.setState({activePosition:position,showPositionDialog:true});
    }

    closePositionDialog = () => {
        this.setState({activePosition:null,showPositionDialog:false});
    }

    showExportOrdersDialog = () => {
        this.setState({showExportOrdersDialog:true});
    }

    closeExportOrdersDialog = () => {
        this.setState({showExportOrdersDialog:false});
    }

    showExportFilesDialog = () => {
        this.setState({showExportFilesDialog:true});
    }

    closeExportFilesDialog = () => {
        this.setState({showExportFilesDialog:false});
    }

    render() {

        let blankItem;

        let blankFilter = {
            name:'',
            filterConditions:[],
            periodAsDate:false
        };

        let displayTypeText = "";

        if(this.state.positionDisplay) {
            displayTypeText = "Positionen anzeigen";
        }else{
            displayTypeText = "Bestellungen anzeigen";
        }

        switch (this.props.kind) {
            case 0:
                blankItem = {
                    orderState:"OFFER_CREATION",
                    orderNr:'',
                    invoiceDate:'',
                    deliveryDate:'',
                    paymentTarget:this.props.company && this.props.company.defaultPaymentTarget ? this.props.company.defaultPaymentTarget : 14,
                    contactPerson:this.props.company && this.props.company.defaultContactPerson ? this.props.company.defaultContactPerson : '',
                    contactEmail:this.props.company && this.props.company.defaultContactEmail ? this.props.company.defaultContactEmail : '',
                    contactPhoneNumber:this.props.company && this.props.company.defaultContactPhoneNumber ? this.props.company.defaultContactPhoneNumber : '',
                    assignedCustomer:null,
                    assignedSupplier:null,
                    documentPositions:[],
                    location:null,
                    fileStorageLocations:[],
                    description:this.props.company.defaultDescription
                }
                break;
            case 1:
                blankItem = {
                    orderState:"OFFER_REQUESTED",
                    orderNr:'',
                    invoiceNr:'',
                    invoiceDate:'',
                    deliveryDate:'',
                    paymentTarget:this.props.company && this.props.company.defaultPaymentTarget ? this.props.company.defaultPaymentTarget : 14,
                    assignedCustomer:null,
                    assignedSupplier:null,
                    documentPositions:[],
                    location:null,
                    fileStorageLocations:[]
                }
                break;
            default:
                break;
        }

        return (
            <div className='orders'>
                <Tooltip id="orders-tooltip"/>
                {this.state.activeItem ?
                    <OrderDialog
                    open={this.state.showItemDialog}
                    close={this.closeItemDialog}
                    order={this.state.activeItem}
                    orders={this.props.items}
                    showMessage={this.props.showMessage}
                    addNewOrder={this.addNewItem}
                    updateOrder={this.updateItem}
                    deleteOrder={this.deleteItem}
                    isNewItem={this.state.isNewItem}
                    setIsNewItem={this.setIsNewItem}
                    round={this.props.round}
                    bookInventoryTransactions={this.props.bookInventoryTransactions}
                    addMultipleEntries={this.addMultipleEntries}
                    addTransactionToHistory={this.props.addTransactionToHistory}
                    updateMultipleAccountsOnList={this.props.updateMultipleAccountsOnList}
                    attributes={this.props.attributes}
                    customers={this.props.customers}
                    suppliers={this.props.suppliers}
                    articles={this.props.articles}
                    company={this.props.company}
                    articleGroups={this.props.articleGroups}
                    displayFile={this.props.displayFile}
                    loadFile={this.props.loadFile}
                    user={this.props.user}
                    kind={this.props.kind}
                    countries={this.props.countries}
                    setActiveOrder={this.setActiveItem}
                    formatDate={this.props.formatDate}
                    logout={this.props.logout}
                    addArticleToList={this.props.addArticleToList}
                    addCustomerToList={this.props.addCustomerToList}
                    addSupplierToList={this.props.addSupplierToList}
                    addOrderToList={this.addItemToList}
                    updateOrderInList={this.updateItemInList}
                    removeOrderFromList={this.removeItemFromList}
                    setLoading={this.props.setLoading}
                    mailTo={this.props.mailTo}
                    downloadFile={this.props.downloadFile}
                    messageTemplates={this.props.messageTemplates}

                    //Email
                    addEmailAttachment={this.props.addEmailAttachment}
                    emailAttachments={this.props.emailAttachments}
                    emailReferencedOrder={this.props.emailReferencedOrder}
                    setEmailReferencedOrder={this.props.setEmailReferencedOrder}
                /> : null}

                {this.state.activePosition ? <PositionDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showPositionDialog}
                    close={this.closePositionDialog}
                    position={this.state.activePosition}
                    kind={this.props.kind}
                    articles={this.props.articles}
                    savePosition={this.savePosition}
                /> : null}

                <FileDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showFileDialog}
                    close={this.closeFileDialog}
                    addNewFileToItem={this.addNewFileToItem}
                    item={this.state.activeItem}
                />

                <FileDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showUploadDialog}
                    close={this.closeUploadDialog}
                    uploadFile={this.uploadFile}
                    item={this.state.activeItem}
                />

                <ExportFilesDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showExportFilesDialog}
                    close={this.closeExportFilesDialog}
                    setLoading={this.props.setLoading}
                />

                {this.props.kind === 2 ? <SendEmailDialog
                    showMessage={this.props.showMessage}
                    open={this.state.showSendMailDialog}
                    close={this.closeSendMailDialog}
                    businessPartners={this.props.businessPartners}
                    fileStorageLocation={this.state.activeItem ? this.state.activeItem.fileStorageLocation : null}
                    company={this.props.company}

                    //Email
                    sendEmail={this.props.sendEmail}
                /> : null}

                <SecureYesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Löschen'
                    text='Wollen Sie das Dokument wirklich löschen?'
                    onClick={() => this.deleteItem(this.state.activeItem)}
                    showMessage={this.props.showMessage}
                />

                <YesCancelDialog
                    open={this.state.showDeleteFilterDialog}
                    close={this.closeDeleteFilterDialog}
                    header='Löschen'
                    text='Wollen Sie den Filter wirklich löschen?'
                    onClick={() => this.deleteFilter(this.state.filter)}
                />

                <YesCancelDialog
                    open={this.state.showExportOrdersDialog}
                    close={this.closeExportOrdersDialog}
                    header='Bestellungen exportieren'
                    text='Wollen Sie ein PDF mit den aktuell angzeigten Bestellungen generieren?'
                    onClick={this.generateDeliverySchedule}
                />

                <YesCancelDialog
                    open={this.state.showRemoveFileDialog}
                    close={this.closeRemoveFileDialog}
                    header='Datei entfernen'
                    text='Wollen Sie die Datei wirklich entfernen?'
                    onClick={() => this.removeFile(this.state.activeItem)}
                />

                <FilterDialog
                    open={this.state.showFilterDialog}
                    close={this.closeFilterDialog}
                    filter={this.state.filter}
                    articles={this.props.articles}
                    customers={this.props.customers}
                    suppliers={this.props.suppliers}
                    saveFilterOnClickHandler={this.saveFilterOnClickHandler}
                    kind={this.props.kind}
                    setLoading={this.props.setLoading}
                />
                <div className='header-bar'>
                    {this.props.company && this.props.company.companySettings && this.props.company.companySettings.executionManagement ?
                        <WarningAmberIcon className="icon warning-icon" onClick={() => this.loadUrgentOrders(true)}
                                          data-tooltip-id="orders-tooltip" data-tooltip-place="top"
                                          data-tooltip-content="Dringende Bestellungen anzeigen"/> : null}
                    {this.props.kind === 0 ? <h1>Kundenbestellungen</h1> : null}
                    {this.props.kind === 1 ? <h1>Lieferantenbestellungen</h1> : null}
                    {this.state.searchType !== "customer-id" && this.state.searchType !== "supplier-id" ?
                        <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} onKeyDown={this._handleKeyDown}
                                   className='field search-input' label='Suchwort' size='small'/> : null}
                    {this.state.searchType === "customer-id" ? <Autocomplete
                        size="small"
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                this.customerOnChange({
                                    id: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                this.customerOnChange({
                                    id: newValue.inputValue,
                                });
                            } else {
                                this.customerOnChange(newValue);
                            }
                        }}
                        className='field'
                        onKeyDown={this._handleKeyDown}
                        disablePortal
                        disableClearable
                        options={this.props.customers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) =>
                            <li {...props}>{option.nr ? option.name + "(" + option.nr + ")" : option.name}</li>}
                        renderInput={(params) => <TextField {...params} label="Kunde"/>}
                    /> : null}
                    {this.state.searchType === "supplier-id" ? <Autocomplete
                        size="small"
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                this.supplierOnChange({
                                    id: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                this.supplierOnChange({
                                    id: newValue.inputValue,
                                });
                            } else {
                                this.supplierOnChange(newValue);
                            }
                        }}
                        className='field'
                        onKeyDown={this._handleKeyDown}
                        disablePortal
                        disableClearable
                        options={this.props.suppliers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) =>
                            <li {...props}>{option.nr ? option.name + "(" + option.nr + ")" : option.name}</li>}
                        renderInput={(params) => <TextField {...params} label="Lieferant"/>}
                    /> : null}
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select onKeyDown={this._handleKeyDown}
                               className='field' label='Suche'>
                        <MenuItem key={0} value={'free-search'}>Freie Suche</MenuItem>
                        <MenuItem key={1} value={'order-nr'}>Bestellnummer</MenuItem>
                        <MenuItem key={2}
                                  value={'commission-nr'}>Kommissionsnummer{this.props.kind === 1 ? " (Verbundene Bestellungen)" : null}</MenuItem>
                        <MenuItem key={3} value={'invoice-nr'}>Rechnungsnummer</MenuItem>
                        <MenuItem key={4} value={'article-nr'}>Artikelnummer</MenuItem>
                        <MenuItem key={5} value={'article-name'}>Artikelname</MenuItem>
                        <MenuItem key={6}
                                  value={'customer-id'}>Kunde{this.props.kind === 1 ? " (Verbundene Bestellungen)" : null}</MenuItem>
                        <MenuItem key={7}
                                  value={'supplier-id'}>Lieferant{this.props.kind === 0 ? " (Verbundene Bestellungen)" : null}</MenuItem>
                        <MenuItem key={9} value={'batch-nr'}>Chargennummer</MenuItem>
                        <MenuItem key={10} value={'batch-material'}>Werkstoff</MenuItem>
                    </TextField>
                    <TextField value={this.state.activeFilter ? this.state.activeFilter.id : null}
                               onChange={this.activeFilterOnChangeHandler} size='small' select InputLabelProps={{shrink: true}} className='field filter-select' onKeyDown={this._handleKeyDown}
                               label='Filter auswählen'>
                        <MenuItem className="filter-item" key={-1} value={null}>Kein Filter</MenuItem>
                        {this.state.filters ? this.state.filters.map((filter, index) => (
                            <MenuItem className="filter-item" key={filter.id} value={filter.id}>
                                {(this.props.kind === 0 && this.props.user.userInformation.defaultCustomerOrderFilter && this.props.user.userInformation.defaultCustomerOrderFilter.id === filter.id) || (this.props.kind === 1 && this.props.user.userInformation.defaultSupplierOrderFilter && this.props.user.userInformation.defaultSupplierOrderFilter.id === filter.id) ?
                                    <GradeIcon onClick={() => this.defaultFilterOnClickHandler(null)}
                                               className="icon default-icon"/> : <StarOutlineIcon
                                        onClick={() => this.defaultFilterOnClickHandler(filter.id)}
                                        data-tooltip-id="orders-tooltip" data-tooltip-place="top"
                                        data-tooltip-content="Als Default festlegen" className="icon default-icon"/>}
                                <EditIcon className="icon" onClick={() => this.showFilterDialog(filter)}/>
                                <DeleteIcon className="icon delete-icon"
                                            onClick={() => this.showDeleteFilterDialog(filter)}/>
                                {filter.name}
                            </MenuItem>
                        )) : null}
                    </TextField>

                    <FilterAltIcon onClick={() => this.showFilterDialog(blankFilter)} className="icon filter-icon"/>

                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>

                    {false ? <div className="switch-holder">
                        <FormControlLabel
                            className='switch-label'
                            control={
                                <Switch className='switch' checked={this.state.positionDisplay}
                                        onChange={this.togglePositionDisplay} color="primary"/>
                            }
                            label={displayTypeText}
                        />
                        {this.state.positionDisplay ?
                            <p className="info-extension">*es werden nur aktive Positionen angezeigt</p> : null}
                    </div> : null}

                    <ul className="sort-options">
                        <li><Paper elevation={3} className="sort-option"
                                   onClick={() => this.sortOnClickHandler("commissionNr")}
                                   style={this.state.sort === "commissionNr" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Komm.-Nr.</Paper>
                        </li>
                        <li><Paper elevation={3} className="sort-option"
                                   onClick={() => this.sortOnClickHandler("orderNr")}
                                   style={this.state.sort === "orderNr" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Best.-Nr.</Paper>
                        </li>
                        {this.props.kind === 0 ?
                            <li><Paper elevation={3} className="sort-option"
                                       onClick={() => this.sortOnClickHandler("customer")}
                                       style={this.state.sort === "customer" ? {
                                           backgroundColor: '#2293BF',
                                           color: 'white'
                                       } : null}>Kunde</Paper>
                            </li> :
                            <li><Paper elevation={3} className="sort-option"
                                       onClick={() => this.sortOnClickHandler("supplier")}
                                       style={this.state.sort === "supplier" ? {
                                           backgroundColor: '#2293BF',
                                           color: 'white'
                                       } : null}>Lieferant</Paper>
                            </li>}
                        <li><Paper elevation={3} className="sort-option"
                                   onClick={() => this.sortOnClickHandler("item")}
                                   style={this.state.sort === "item" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Gegenstand</Paper>
                        </li>
                        <li><Paper elevation={3} className="sort-option"
                                   onClick={() => this.sortOnClickHandler("orderDate")}
                                   style={this.state.sort === "orderDate" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Best.-Dat.</Paper>
                        </li>
                        <li><Paper elevation={3} className="sort-option"
                                   onClick={() => this.sortOnClickHandler("deliveryDate")}
                                   style={this.state.sort === "deliveryDate" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Lief.-Dat.</Paper>
                        </li>
                        <li><Paper elevation={3} className="sort-option" onClick={() => this.sortOnClickHandler("note")}
                                   style={this.state.sort === "note" ? {
                                       backgroundColor: '#2293BF',
                                       color: 'white'
                                   } : null}>Bemerkung</Paper>
                        </li>

                    </ul>

                </div>
                {this.state.positionDisplay ? <div className="item-list">
                        {this.sortedPositions().map((position, index) => (
                            <PositionOverviewItem
                                key={position.id}
                                index={index}
                                position={position}
                                kind={this.props.kind}
                                showPositionDialog={this.showPositionDialog}
                                articles={this.props.articles}
                            />
                        ))}
                    </div> :
                    <div className="item-list">
                        {this.sortedItems().map((item, index) => (
                            <OrderItem
                                key={item.id}
                                index={index}
                                user={this.props.user}
                                item={item}
                                showItemDialog={() => this.showItemDialog(false, item)}
                                showFileDialog={() => this.showFileDialog(item)}
                                deleteItem={() => this.showDeleteItemDialog(item)}
                                displayFile={() => this.props.displayFile(item.fileStorageLocation)}
                                showRemoveFileDialog={() => this.showRemoveFileDialog(item)}
                                showSendMailDialog={() => this.showSendMailDialog(item)}
                                kind={this.props.kind}
                                formatDate={this.props.formatDate}
                                setLoading={this.props.setLoading}
                                commissionNrOnChangeHandler={this.commissionNrOnChangeHandler}
                                orderNrOnChangeHandler={this.orderNrOnChangeHandler}
                                itemOnChangeHandler={this.itemOnChangeHandler}
                                orderDateOnChangeHandler={this.orderDateOnChangeHandler}
                                deliveryDateOnChangeHandler={this.deliveryDateOnChangeHandler}
                                noteOnChangeHandler={this.noteOnChangeHandler}
                                updateOrder={this.updateOrder}
                            />))}
                    </div>
                }
                {this.state.loadingSearch ? <Box className="loading-holder">
                    <CircularProgress className='load-icon'/>
                </Box> : null}
                {false && this.props.kind === 1 ?
                    <ToolFab className='upload-fab' onClick={() => this.showUploadDialog()} aria-label="upload">
                        <UploadFile/>
                    </ToolFab>
                    : null}
                {!this.state.positionDisplay ? <ToolFab className='add-fab' onClick={() => this.showItemDialog(true, blankItem)}
                         aria-label="add">
                    <AddIcon/>
                </ToolFab> : null}
                {!this.state.positionDisplay ? <ToolFab className='export-fab' data-tooltip-id="orders-tooltip" data-tooltip-place="top"
                                                        data-tooltip-content="Dateien exportieren"
                                                        onClick={this.showExportFilesDialog}
                                                        aria-label="export">
                    <DownloadIcon/>
                </ToolFab> : null}
                {!this.state.positionDisplay ? <ToolFab className='pdf-fab' data-tooltip-id="orders-tooltip" data-tooltip-place="top"
                                                        data-tooltip-content="Bestellungen als PDF exportieren"
                                                        onClick={this.showExportOrdersDialog}
                                                        aria-label="generate">
                    <PictureAsPdfIcon/>
                </ToolFab> : null}
            </div>
        )
    }
}

export default Orders;